import React, { useEffect, useState } from "react";
import { useGetCurrency } from "../../../Services/useGetCurrency";
import { CashDeskProps } from "../../../Services/useGetCashDesk";
import { Checkbox, CheckboxProps, Form, FormInstance, Select } from "antd";
import { useCashDesk } from "../../../Services/useCashDesk";
import './styles.scss';
import { ResponseAccounts, useAccounts } from "../../../Services/useAccounts";
import { sign } from "crypto";
import { DataType } from "../../../Contexts/UserContext/MovementAcountContext";


export const FormItemsBuy = React.memo<{
    form_buy_and_sell: FormInstance<any>
    secondCurrency: {
        id: string,
        sign: string,
        name: string
    } | undefined,
    setSecondCurrency: Function,
    customerSelected: {
        id: string,
        name: string
    } | undefined
    balance: ResponseAccounts | undefined
    setBalance: Function,
    is_main: boolean,
    rowSelect?: DataType
}>  (({
    form_buy_and_sell,
    secondCurrency, 
    setSecondCurrency,
    customerSelected,
    balance, 
    setBalance,
    is_main,
    rowSelect
}) =>{   


    const { Option } = Select;
    const {data: currencies} = useGetCurrency()
    const {getAccountsCurrencyAndCustomer} = useAccounts()  
    const {getCashCurrency} = useCashDesk()  
    const [checkedDeposit, setCheckedDeposit] = useState(false);  
    const [listCahs, setListCash] = useState<CashDeskProps[]>()
    const [spanError, setSpanError] = useState<string>()
    const [valueCurrency] = useState( is_main == true
        ? {
            value: 'Thether', //'Dolar
            label: 'Thether', //Dolar
            key: '3', //2
            sign: 'USDT' //'USD
        }
        : rowSelect
            ? {
                value:  secondCurrency?.name || '', 
                label: secondCurrency?.name || '', 
                key: secondCurrency?.id || '',
                sign: secondCurrency?.sign || '' 
            }
            :undefined
    )
    const [valueDisabled] = useState( is_main
        ? true
        : (rowSelect?.deposit_operation_approved == 'true' || rowSelect?.extraction_operation_approved == 'true' ) ? true : false)

    const mandatory = [
        {
            required: true,
            message:'Este campo es obligatorio'
        }
    ]   

    useEffect(() => {
        setSpanError(undefined)
        let deposit_cash_value = form_buy_and_sell.getFieldValue('deposit_cash');        
        initialValuesForm()        
        if(deposit_cash_value && deposit_cash_value.key){
            setCheckedDeposit(true)
        }else{
            setCheckedDeposit(false)
            setListCash(undefined)
        }
      }, []);

    useEffect(()=>{
        if(secondCurrency && customerSelected){
            getBalanceCash()
        }
        
    },[secondCurrency, customerSelected])

    const initialValuesForm = async () => { 
        const currencyId = is_main ? 3 : secondCurrency?.id;
        const currencySign = is_main ? 'USDT' : secondCurrency?.sign;
    
        if (currencyId) {
            const data = await getCashCurrency(Number(currencyId));
            
            if (data) {
                let is_not_bank = data.filter((x)=> x.type.id !== 2)
                setListCash(is_not_bank)
                if (data.length === 0) {
                    setSpanError(`No existen cajas en ${currencySign}. Cree una en Menú -> Administración -> Cajas`);
                } else if (spanError) {
                    setSpanError(undefined);
                }
            }
        }
    };


    const getBalanceCash = async() =>{
        if(secondCurrency && customerSelected){
            const data = await getAccountsCurrencyAndCustomer(Number(customerSelected.id), Number(secondCurrency.id))
            if(data){
                setBalance((prevData: ResponseAccounts | undefined) => {
                    if (JSON.stringify(prevData) !== JSON.stringify(data)) {
                        return data;
                    }
                    return prevData; // No actualizar si son iguales
                });
            }
        }
    }

    const onChangeDeposit: CheckboxProps['onChange'] = async (e) => {
        setCheckedDeposit(e.target.checked);
        form_buy_and_sell.setFieldValue('deposit_cash', undefined); 
    };

    const onChangeCurrency = async(values: {key:string, label: string}, record: any)=>{   
        setSecondCurrency({
            id: values.key,
            sign: record?.sign ? record.sign : undefined,
            name: values.label
        })   
        form_buy_and_sell.setFields([
            {
                name: 'buy_currency',
                errors: [],
            },
        ]);     
        form_buy_and_sell.setFieldValue('deposit_cash', undefined); 
        const data = await getCashCurrency(Number(values.key)) 
        if(data){
            let is_not_bank = data.filter((x)=> x.type.id !== 2)
            setListCash(is_not_bank)
            if(data.length == 0){
                setSpanError(`No existen cajas en ${ record?.sign ? record.sign : ''}. Cree una en Menú -> Administración -> Cajas`)
            }else{
                if(spanError){
                    setSpanError(undefined)
                }
            }
        }              
    }     


    return(
    <div className="section_buy">       
        <Form.Item
            name='buy_currency'
            className='sell_and_buy_divide'
        > 
            <div className="section_buy_title">Moneda compra</div> 
            <Select                           
                labelInValue  
                defaultValue={valueCurrency}
                loading={!currencies }
                placeholder={'Moneda'}                       
                style={{minWidth: '105px'}}
                onChange={(values, record)=>{
                    onChangeCurrency(values, record)
                }}
                disabled={valueDisabled}   
            >
                {currencies && currencies && currencies.map((item) => (
                    item.id !== 3 &&
                    <Option value={item.name} key={item.id} sign={item.sign} >
                        {item.name}
                    </Option>
                ))} 
            </Select>
        </Form.Item>
        <div 
            style={{
                display: 'flex',
                alignContent: 'center'
            }}
        >
            <p style={{padding: '0px', fontWeight: '500', marginRight: '10px'}}>SALDO DE CUENTA CLIENTE:</p>
            <p style={{padding: '0px', fontWeight: '500', color: balance?.balance && balance.balance >= 0 ? 'green' : 'red'}}>
                {balance && secondCurrency && balance.currency_id === Number(secondCurrency.id) ? secondCurrency.sign : ''} {' '}
                {balance? new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(balance.balance) : ''}
            </p>
        </div>      
        <Form.Item>
            <Checkbox
                checked={checkedDeposit}
                onChange={onChangeDeposit}
                disabled={rowSelect?.deposit_operation_approved == 'true'  ? true : false} 
            >
                Depósito en caja
            </Checkbox>
        </Form.Item>
        {
            checkedDeposit &&
            <Form.Item
                label='Caja depósito'
                name='deposit_cash' 
                rules={mandatory}
            >
                <Select                           
                    labelInValue  
                    loading={!listCahs }
                    placeholder={!listCahs ? 'Cargando cajas...' : 'Seleccione una caja'}      
                    style={{minWidth: '200px'}}         
                    disabled={rowSelect?.deposit_operation_approved == 'true'  ? true : false}                         
                >
                    {listCahs && listCahs.map((item) => (
                        <Option value={item.name} key={item.id} >
                            {item.name}
                        </Option>
                    ))} 
                </Select>
            </Form.Item>
        }     

        
        
       {spanError && <span style={{color: 'red'}}>{spanError}</span>}              
    </div>)
})