import { useContext, useEffect } from 'react';
import useSWR from 'swr';
import { axiosBackEndInstance } from '../Axios';
import { UserContext } from '../Contexts/UserContext/UsersContext';
import { useNavigate } from 'react-router';

const fetcher = async (
  path: string, 
  params?: URLSearchParams
  ): Promise<any> => {
  try {
    const {
      data,
    }: { data: any } = await axiosBackEndInstance.get(
        path,
        {params}
    );
    return data;
  } catch (newError) {
    console.log(newError);
    throw newError;
  }
};

export const useAuthSWR =(
  url: string[],
  params?:  URLSearchParams
) => {

  const { setAccesToken } = useContext(UserContext)
  const navigate = useNavigate()
  
  const { data, error, mutate } = useSWR(
    url, 
    ([url]) => fetcher(url, params ? params : undefined),
    {
      onErrorRetry(error, key, config, revalidate, { retryCount }) {
        if (retryCount >= 1) return;        
        return;       
      },      
    }  
  )

  useEffect(() => {
    if(error && error !== null && error.response && error.response.status && error.response.status === 401){// token vencido o no autorizado 
      if(error.response.statusText == "Unauthorized"){
        console.log(error)
        console.log(url)
        mutate(null, false)
        setAccesToken(undefined)
        navigate('/dashboard')
      }
    }
  }, [error]);

  return {
    data,
    error,
    mutate,
  };
}
