import { useContext, useEffect } from 'react';
import useSWR, { KeyedMutator } from "swr";

import { useAuthSWR } from './useAuthSWR';
import { END_POINT_BRANCH_GET_ALL } from '../Axios/ENDPOINTS';

interface CashDesk {
  id: number;
  description: string;
  branch_id: number;
  currency: {
    name: string;
    sign: string;
  };
  balance: number;
}

export interface BranchProps {
  id: number;
  name: string;
  address: string;
  city: string;
  cash_desks: CashDesk[];
}


export interface ResponseGetUsers{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: BranchProps[]  
}

export const useGetBranch = () => {
    
    const {data, error, mutate }:{
      data: BranchProps[], 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([END_POINT_BRANCH_GET_ALL])    

  
    return {
      data,
      mutate,
      error
    };
};