import React, { useContext, useState } from "react"
import {
    CheckOutlined,
  } from '@ant-design/icons';
import { useOperations } from "../../Services/useOperations";
import './styles.scss'
import { DataType } from "../../Contexts/UserContext/MovementAcountContext";
import { BalancesContext } from "../../Contexts/UserContext/BalancesContext";

export const RenderApproved: React.FC<{
  text: string, 
  record: DataType, 
  setDataTable: Function,
  dataTable: DataType[]
  handleSearch: Function
}> = ({
    text, 
    record, 
    setDataTable, 
    dataTable, 
    handleSearch
  }) =>{

  const {confirmOperation} = useOperations()
  const { setMutateBalance } = useContext(BalancesContext) 

  const [loadingApproved, setLoadingApproved] = useState(false)
  const handleApproved = async ()=>{
    setLoadingApproved(true)
    const status = await confirmOperation(+record.id, setLoadingApproved)
    if(status == 200 ){
      handleSearch(false)
      setMutateBalance(true)
    }
  }

  return(
    <>
      {
        text == 'true'
        ? <CheckOutlined style={{color: 'greenyellow', paddingLeft: '10px'}} />
        : (
            (record.full_name && record.operation_type_id !== '7')  || 
            record.related_operation_id  ||//depósito o extracción vinculada a una compra venta usdt
            record.operation_type_id == '8' )
            ? <p className="pending">Pendiente</p>
            : <p
                onClick={handleApproved} 
                className={loadingApproved ? "loading_btn":"approve_btn"}
                //style={{color: loadingApproved ? 'grey' :'orange ', padding: '0px', margin: '0px', }}
              >
                { loadingApproved ? 'cargando...' : 'Aprobar' }
              </p> 
      }    
    </>
  )
  }