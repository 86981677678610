import React, { useContext, useEffect, useState } from 'react';
import './styles.scss'
import {  Button, Col, Modal, Row, Select, Table, Form } from 'antd';
import {
    DeleteOutlined,    
  } from '@ant-design/icons';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { UsersSectionProps } from './types';
import { Transaction, useGetMovements } from '../../../Services/useGetMovements';
import { useGetAllClients } from '../../../Services/useGetAllClients';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';

export interface DataType {
  index: string,
  id: string;
  client: string,  
  main_currency: string,
  main_currency_balance: String
  main_currency_amount: string,
  secundary_currency: string,
  secundary_currency_balance: string,
  secundary_currency_ammount: string,
  account_balance: string,
}

const PurchaseSaleCurrency: React.FC<UsersSectionProps> = () => {

  const {data: dataApi} = useGetMovements()
  const {data: clients} = useGetAllClients()
  const { Option } = Select;
  const {adjustedTableHeigthScroll} = useContext(StylesContext)



  const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
  const [dataTable, setDataTable] = useState<DataType[]>([])
  const [openForm, setOpenForm] = useState<{
    deposit_extraction: boolean,
    transactions: boolean,
    buy_and_sell: boolean,
  }>({
    deposit_extraction: false,
    transactions: false,
    buy_and_sell: false,
  })
  const [rowSelect, setRowSelect] =useState<DataType | undefined>()    
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  useEffect(()=>{
    setLoadingTableData(true)
    if(dataApi){
        rowData() 
        setLoadingTableData(false)
    }
  },[dataApi])  

  const rowData = (data? : Transaction[]) =>{
    let filterData: DataType[]= []
    let list: Transaction[] = data ? data : dataApi 

    filterData.push({
      index: '1',
      id: '1',
      client: 'Jueliana Perez',  
      main_currency: 'USD',      
      main_currency_amount: '1200',
      main_currency_balance: '2200',
      secundary_currency: 'USD',      
      secundary_currency_ammount: '1.680.000',
      secundary_currency_balance: '1.000.000',
      account_balance: '2200',
    })   
    setDataTable(filterData)
  }

  const callback = () =>{
      setLoadingTableData(false)
      setRowSelect(undefined)        
      setShowConfirmDelete(false)
      setOpenForm({
        deposit_extraction: false,
        transactions: false,
        buy_and_sell: false,
      })
  }

  const handleRenderer = (text: string, record: DataType) =>{
    return (
        <div className='currency_action_action_render'>
            <DeleteOutlined 
                onClick={()=>{
                    setRowSelect(record)
                    setShowConfirmDelete(true)
                }}
                style={{marginLeft:'30px', color: '#37bde0'}} /> 
        </div>  
    )      
  }
    
  const ModalConfirmDelete = () => {
    return(
        <Modal
            title="Eliminar"
            open={showConfirmDelete}
            onOk={()=>{
                if(rowSelect && rowSelect.id){
                    //deleteBranch(callback, rowSelect.id)
                }}}
            onCancel={()=>setShowConfirmDelete(false)}
            okText="Eliminar"
            cancelText="Cancelar"
        >
            <p>¿Estás seguro de que desea eliminar la operación?</p>
        </Modal>
    )
  }

  const columns: ColumnsType<DataType> = [
    {
        title: 'Cliente',
        dataIndex: 'client',
        key: '1',
        className:'cursor-pointer',
    },
    {
      title: 'Moneda principal',
      dataIndex: 'main_currency',
      key: '2',
      className:'cursor-pointer',    
    },
    {
      title: 'Monto',
      dataIndex: 'main_currency_amount',
      key: '3',
      className:'cursor-pointer'
    },
    {
      title: 'Saldo cuenta',
      dataIndex: 'main_currency_amount',
      key: '4',
      className:'cursor-pointer'
    },    
    {
      title: 'Moneda secundaria',
      dataIndex: 'secundary_currency',
      key: '5',
      className:'cursor-pointer',
    },
    {
      title: 'Mmonto',
      dataIndex: 'secundary_currency_ammount',
      key: '6',
      className:'cursor-pointer',
    },
    {
      title: 'Saldo cuenta',
      dataIndex: 'secundary_currency_balance',
      key: '7',
      className:'cursor-pointer',
    },
    {
      title: 'Saldo',
      dataIndex: 'account_balance',
      key: '8',
      className:'cursor-pointer',
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      key: '8',        
      render:  handleRenderer,
      width: 110,      
    }, 
  ];
    
  const onClickRow = (record: DataType) =>{         
    setRowSelect(record)
    //setOpenForm(true) 
  }    

  return( 
    <>
      <ModalConfirmDelete/>    
      <div className='purchase_sale_currency_section purchase_sale_currency_section_container'>                                  
          <Row
            className='global_title_section'
          >
            <h3>COMPRA / VENTA MONEDA</h3>                
          </Row>
          <Row 
            className='global_button_box'
          >
            <Col>
              <Form
                layout='inline'
              >
                <Form.Item
                    name='client'
                    rules={[
                      {
                          required: true,
                          message:'Este campo es obligatorio'
                      }
                    ]}
                >
                  <Select                           
                      labelInValue  
                      loading={!clients || clients.length === 0}
                      placeholder={!clients || clients.length === 0 ? 'Cargando campos...' : 'Cliente'}      
                      style={{minWidth: '200px'}}                               
                  >
                      {clients && clients.map((item) => (
                          <Option value={item.first_name + ' ' + item.last_name} key={item.id} >
                            {item.first_name + ' ' + item.last_name}
                          </Option>
                      ))} 
                  </Select>
                </Form.Item>              
                <Form.Item>
                  <Button
                  type='primary'
                  className='purchase_sale_currency_section_btn'
                  >
                    Buscar
                  </Button>
                </Form.Item>
              </Form>                 
            </Col>                        
          </Row>
          <Row 
            style={{
                display:'flex',
                justifyContent: 'center',
                width: '100%',
                height: '80%',
                padding: '0px 30px',                        
                border: '1px solid   #645fca6c'
            }}
          >
            {
              loadingTableData
              ? <SpinerHorizontal/> 
              : <Table 
                  style={{
                      width: '100%',
                      marginTop: '2%'
                  }}
                  columns={columns} 
                  dataSource={dataTable}  
                  scroll={{ y: adjustedTableHeigthScroll }}                                   
                  pagination={{
                      pageSize: 50,
                      showSizeChanger: false,                                      
                  }}
                />    
              } 
          </Row>             
      </div>
    </>
  )
};

export default PurchaseSaleCurrency;