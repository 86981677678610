import React, { useContext } from 'react';
import './styles.scss';
import logo from '../../assets/logo/logo5.png'
import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { UserContext } from '../../Contexts/UserContext/UsersContext';
import { useNavigate } from 'react-router';

const Navbar: React.FC = () => {

    const { userData, setAccesToken } = useContext(UserContext);
    const navigate = useNavigate();
    
    const items: MenuProps['items'] = [
        {
          key: '1',
          label: 'Perfil',
          onClick: ()=>{
            navigate('/dashboard/profile');
          }
        },
        {
          key: '2',
          label: 'Cambiar contraseña',
          onClick: ()=>{
            navigate('/dashboard/change_password');
          }
        },
        {
          key: '3',
          label: 'Cerrar sesión',
          onClick: ()=>{
            setAccesToken(undefined)
            window.location.reload()
          }
        },
      ];
  
    return( 
        <div className='navbar_container'>
           <img src={logo}/>            
            <Dropdown
                trigger={['click']}
                menu={{
                items,
                }}               
            >
                <div className='navbar_profile'>
                    {
                      userData?.first_name && userData?.last_name &&
                        <h4>{userData?.first_name} {userData?.last_name}</h4>                        
                    }                  
                                      
                    <DownOutlined style={{
                        color:'white',
                        marginLeft: '15px',
                        marginTop:'3px',
                        fontSize: '12px',
                        transition: 'all 0.2s ease-in-out',
                    }} />  
                </div>                              
            </Dropdown>   
        </div>                
    )};

export default Navbar;

// overlay={ <Menu>
//     <Menu.Item>
//         <Link
//             className="dashboard-top-nav-bar__dropdown-item"
//             to="/dashboard/profile"
//         >
//             Perfil
//         </Link>
//     </Menu.Item>
//     <Menu.Item>
//         <Link
//             className="dashboard-top-nav-bar__dropdown-item"
//             to="/"
//         >
//             Cerrar sesión
//         </Link>
//     </Menu.Item>
// </Menu>}