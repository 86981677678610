import React, { useContext, useEffect, useState } from "react";
import './styles.scss';
import {  Checkbox, CheckboxProps, Form, Input, InputNumber, Modal, Select } from "antd";
import { PucrchaseSaleCurrencyFormProps } from "./types";
import { useGetAllClients } from "../../../Services/useGetAllClients";
import { FormItemsBuy } from "./FormItemsBuy";
import { FormItemsSell } from "./FormItemsSell";
import { ResponseAccounts } from "../../../Services/useAccounts";
import './styles.scss';
import { useOperations } from "../../../Services/useOperations";
import { useCurrency } from "../../../Services/useCurrency";
import { VariablesContext } from "../../../Contexts/UserContext/VariablesContext";
import { UserContext } from "../../../Contexts/UserContext/UsersContext";

export interface FilterValues{
    value: string,
    label: string,
    key: number,
}

export interface ValuesForm {
    operation_type: FilterValues,
    client: FilterValues,   
    sell_currency: FilterValues,
    extraction_cash: FilterValues,
    buy_currency: FilterValues,
    deposit_cash: FilterValues,
    type_change: string,
    amount: string,
    description: string,
    approved: boolean
}


export const PucrchaseSaleCurrencyForm: React.FC<PucrchaseSaleCurrencyFormProps> =({
    form_buy_and_sell,
    rowSelect,
    setRowSelect
})=>{

    const {data: clients} = useGetAllClients()    
    const {createOperation, editOperation} = useOperations()
    const {getExchangeRate} = useCurrency()
    const {collapsedFilsters, setCollapsedFilters, setShowForms, showForms} = useContext(VariablesContext) 
    const { userData } =useContext(UserContext)
    const [ spanError, setSpanError ] = useState<string | undefined>()      
    const { Option } = Select;

    
    const mandatory = [
        {
            required: true,
            message:'Este campo es obligatorio'
        }
    ]    
    
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [operationType, setOperationType] = useState<'sell' | 'buy' | undefined>()
    const [aproved, setAproved] = useState(false)    
    const [balance, setBalance] = useState<ResponseAccounts | undefined>()
    const [count, setCount] = useState(0)
    const [customerSelected, setCustomerSelected] = useState<{
        name: string,
        id: string,
    }>()
    const [selectedSellingCurrency, setSelectedSellingCurrency] = useState<{
        id: string,
        sign: string,
        name: string
    }>()

    const [selectedPurchaseCurrency, setSelectedPurchaseCurrency] = useState<{
        id: string,
        sign: string,
        name: string
    }>()

    useEffect(()=>{        
        if(rowSelect){
            let operationType: 'sell' | 'buy'  = rowSelect.currency_sale === 'true' ? 'sell' : 'buy';
            let type_change = ''
            setOperationType(operationType);          
            //Si la financiera compra: Hay extracción de origin_account, puede haber depósito en checkout_destination  
            if(rowSelect.origin_account_currency_id){
                setSelectedPurchaseCurrency({
                    id: rowSelect.origin_account_currency_id,
                    sign: rowSelect.origin_account_currency_sign,
                    name: rowSelect.origin_account_currency_name
                })
            }     
            //Si la financiera vende: Hay depósito en origin_account, puede haber extracción en checkout_destination   
            if(rowSelect.destination_account_currency_id){
                setSelectedSellingCurrency({
                    id: rowSelect.destination_account_currency_id,
                    sign: rowSelect.destination_account_currency_sign,
                    name: rowSelect.destination_account_currency_name
                })
            }      
            if(rowSelect.currency_quote_buy_price){
                type_change = rowSelect.currency_quote_buy_price
            }
            if(rowSelect.currency_quote_sell_price){
                type_change = rowSelect.currency_quote_sell_price
            }

            form_buy_and_sell.setFieldsValue(
                { 
                    operation_type: {
                        value: operationType, 
                        label:  rowSelect.currency_sale === 'true' ? 'Venta' : 'Compra',
                    },
                    client: {
                        value: rowSelect.customer_first_name + ' ' + rowSelect.customer_last_name,
                        label: rowSelect.customer_first_name + ' ' + rowSelect.customer_last_name,
                        key: rowSelect.customer_id,
                    },
                    deposit_cash:{
                        value: rowSelect.destination_checkout_name || undefined,
                        label: rowSelect.destination_checkout_name || undefined,
                        key: rowSelect.destination_checkout_id || undefined,
                    },
                    extraction_cash:{
                        value: rowSelect.origin_checkout_name || '',
                        label: rowSelect.origin_checkout_name || '',
                        key: rowSelect.origin_checkout_id || '',
                    },
                    type_change: type_change,
                    amount: operationType == 'sell' ? rowSelect.credit_number : rowSelect.debit_number,
                    description: rowSelect.description
                }
            )
            setCount(1)
        }else{            
            form_buy_and_sell.resetFields()
            setSelectedSellingCurrency(undefined)
            setSelectedPurchaseCurrency(undefined)
        }
    },[])

    useEffect(()=>{
        if(selectedPurchaseCurrency && selectedSellingCurrency){
            if(rowSelect){
                setCount(2)
                if(count > 1){
                    checkExchangeRate()                 
                }
            }else{
                checkExchangeRate()               
            } 
        } 
             
    },[selectedPurchaseCurrency, selectedSellingCurrency])

    const handleCancel =()=>{
        setShowForms(Object.fromEntries(Object.keys(showForms).map(key => [key, false])))// pone todos en false
        setLoadingBtn(false)
        form_buy_and_sell.resetFields()
        if(setRowSelect){
            setRowSelect(undefined)
        }
    }

    // const getBalance = async() =>{   
    //     const data = await getAccountsCurrencyAndCustomer(Number(client), Number(currency?.id))
    //     if(data){
    //         setBalance(data)
    //     }        
    // }

    const checkExchangeRate = async()=>{
        let main_currency_id 
        let secondary_currency_id
        
        if(selectedSellingCurrency && selectedPurchaseCurrency){
            switch(operationType){
                case 'buy':
                    main_currency_id = selectedPurchaseCurrency.id
                    secondary_currency_id = selectedSellingCurrency.id
                    break
                case 'sell':
                    main_currency_id = selectedSellingCurrency.id
                    secondary_currency_id = selectedPurchaseCurrency.id
                    break;
                default:
                    main_currency_id = undefined
                    secondary_currency_id = undefined
                    break;
            }
            if(main_currency_id && secondary_currency_id){
                const data = await getExchangeRate( +main_currency_id, +secondary_currency_id)
                if (data){
                    switch(operationType){
                        case 'buy':
                            form_buy_and_sell.setFieldValue('type_change', data.buy_price)
                            break;
                        case 'sell':
                            form_buy_and_sell.setFieldValue('type_change', data.sell_price)
                            break;
                        default:
                            setSpanError('No hay tipo de cambio, cree uno o ingreselo manual')
                            break;
                    }                    
                }
            }
        }        
    }

    const clearState = () =>{
        if(setRowSelect){
            setRowSelect(undefined)
        }
        setSelectedSellingCurrency(undefined)
        setSelectedPurchaseCurrency(undefined)
        setBalance(undefined)
        form_buy_and_sell.resetFields([
            'client', 
            'buy_currency', 
            'deposit_cash', 
            'sell_currency', 
            'extraction_cash', 
            'type_change', 
            'aproved', 
            'amount', 
            'description'
        ]);
    }
      
    const handleSave = async (values: ValuesForm)=>{
        setLoadingBtn(true)
        if(selectedPurchaseCurrency && selectedSellingCurrency && userData){
            let newData
            let operationData={
                description: values.description || '',
                type_id: 7, //id de compra venta moneda              
                main_currency_id: operationType == 'buy' ? +selectedPurchaseCurrency.id : +selectedSellingCurrency.id,
                secondary_currency_id:  operationType == 'buy' ?  +selectedSellingCurrency.id  : +selectedPurchaseCurrency.id, 
                user_id: userData.id,
                debit: Number(values.amount),
                currency_quote_sell_price: operationType =='sell' ? Number(values.type_change) : undefined,
                currency_sale: operationType =='sell' ? true : false,
                approved: aproved,
                customer_id: Number(values.client.key),
                currency_quote_buy_price: operationType =='buy' ? Number(values.type_change) : undefined ,//No está más?                      
                origin_checkout_id: values.extraction_cash ? Number(values.extraction_cash.key) : undefined, //Hubo extracción en cuenta  
                destination_checkout_id: values.deposit_cash?.key ?  Number(values.deposit_cash.key) : undefined, //Hubo depósito en cuenta                           
            }
            if(rowSelect){
                newData = await editOperation(handleCancel, rowSelect.id, operationData)
            }else{
                newData = await createOperation(handleCancel, operationData)
            }
            // if(newData){
            //     if (showingAccount && showingAccount.user_id == newData.customer.id &&
            //         (
            //             showingAccount.sign_id == newData.origin_account?.currency.id || 
            //             showingAccount.sign_id == newData.destination_account?.currency.id
            //         ))
            //     {
            //         setMutate(true)
            //     }
            //     mutateOperationsSections(true)
            // }         
            //checkAccountBalance()
            setCollapsedFilters(!collapsedFilsters)  
        }                       
    }  
    
    const handleOperationType = (event:{value:'sell' | 'buy' } ) =>{
        if(event){
            setOperationType(event.value)
        }
        clearState()  
        setAproved(false)     
    }   

    const handleChangeClients = (values: {
        label: string,
        value: string,
        key: string
    }) =>{
        setCustomerSelected({
            id: values.key,
            name: values.label
        })
    }

    const onChangeAproved: CheckboxProps['onChange'] = async (e) => {
        setAproved(e.target.checked); 
    };
    
    const amountSign = () => {
        let sign = '$'        
        switch(operationType){
            case 'buy':
                if(selectedPurchaseCurrency){
                    sign = selectedPurchaseCurrency.sign
                }
                break
            case 'sell':
                if(selectedSellingCurrency){
                    sign = selectedSellingCurrency.sign
                }
                break;
            default:
                sign= '$'
                break;
        }
        return sign
    };

    const amountExangeRate = () => {
        let sign = '$'               
        switch(operationType){
            case 'buy':
                if(selectedSellingCurrency){
                    sign = selectedSellingCurrency.sign
                }
                break
            case 'sell':
                if(selectedPurchaseCurrency){
                    sign = selectedPurchaseCurrency.sign
                }
                break;
            default:
                sign= '$'
                break;
        }
        return sign
    }; 

 
    return(
        <div>            
            <Modal
                title= 'Compra / Venta: Cliente'
                open={showForms.buy_and_sell }
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={()=>form_buy_and_sell.submit()}
            >
                <Form
                    form={form_buy_and_sell}
                    id='form_deposit_extraction'
                    onFinish={handleSave}
                    layout="vertical"                    
                >      
                    <Form.Item
                        label='Tipo de operación'
                        name='operation_type'
                        rules={mandatory}
                    >
                        <Select                           
                            labelInValue  
                            placeholder='Seleccione el tipo de operación'   
                            disabled={(rowSelect?.deposit_operation_approved == 'true' || rowSelect?.extraction_operation_approved == 'true' ) ? true : false}
                            onChange={(event)=> handleOperationType(event)}          
                            options={[
                                { value: 'sell', label: 'Venta' },
                                { value: 'buy', label: 'Compra' },
                            ]}                   
                        />
                    </Form.Item> 
                    <Form.Item
                        label='Cliente'
                        name='client'
                        rules={mandatory} 
                    >
                        <Select                           
                            labelInValue  
                            loading={!clients }
                            placeholder={!clients ? 'Cargando campos...' : 'Cliente'}      
                            style={{minWidth: '200px'}}  
                            onChange={handleChangeClients}       
                            disabled={(rowSelect?.deposit_operation_approved == 'true' || rowSelect?.extraction_operation_approved == 'true' ) ? true : false}             
                        >
                            {clients && clients.map((item) => (
                                <Option value={item.first_name + ' ' + item.last_name} key={item.id} >
                                    {item.first_name + ' ' + item.last_name}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>  
                    {
                        operationType && 
                        <>
                            {  
                               operationType == 'buy'
                                ? 
                                    <>
                                        <FormItemsBuy 
                                            form_buy_and_sell={form_buy_and_sell}     
                                            setSelectedPurchaseCurrency={setSelectedPurchaseCurrency}  
                                            selectedPurchaseCurrency={selectedPurchaseCurrency}
                                            customerSelected={customerSelected}       
                                            balance={balance}
                                            setBalance={setBalance} 
                                            rowSelect={rowSelect}                                   
                                        />
                                        <FormItemsSell  
                                            form={form_buy_and_sell}
                                            setSelectedSellingCurrency={setSelectedSellingCurrency}  
                                            selectedSellingCurrency={selectedSellingCurrency}   
                                            rowSelect={rowSelect}                                                                                    
                                        />
                                    </>                                    
                                :      
                                    <>              
                                         <FormItemsSell  
                                            form={form_buy_and_sell}
                                            setSelectedSellingCurrency={setSelectedSellingCurrency}  
                                            selectedSellingCurrency={selectedSellingCurrency}   
                                            rowSelect={rowSelect}                                                                                    
                                        />
                                       <FormItemsBuy 
                                            form_buy_and_sell={form_buy_and_sell}     
                                            setSelectedPurchaseCurrency={setSelectedPurchaseCurrency}  
                                            selectedPurchaseCurrency={selectedPurchaseCurrency}
                                            customerSelected={customerSelected}       
                                            balance={balance}
                                            setBalance={setBalance}      
                                            rowSelect={rowSelect}                               
                                        />
                                    </>
                            }
                            
                            <Form.Item
                                label= 'Tipo de cambio'         
                                name= 'type_change'
                                className="sell_and_buy_divide"  
                            >
                                <InputNumber
                                    addonBefore={amountExangeRate()}
                                />
                            </Form.Item>
                            <Form.Item
                                label= {operationType == 'buy' ? 'Monto de compra' : 'Monto de venta'}         
                                name= 'amount'
                            >
                                <InputNumber
                                    addonBefore={amountSign()}
                                />                    
                            </Form.Item>
                        </>                                          
                    }  
                     <Form.Item
                        name='aproved'
                        valuePropName="checked"
                    >
                        <Checkbox
                            checked={aproved}
                            onChange={onChangeAproved}
                        >
                            Aprobado
                        </Checkbox>
                    </Form.Item>
                     <Form.Item
                        label='Descripción'
                        name='description'
                    >
                        <Input
                            placeholder="Comentario"
                        />
                    </Form.Item>                                  
                </Form>
                <span style={{color: 'red'}}>{spanError && spanError}</span>
            </Modal>
        </div>
    )
}

export default PucrchaseSaleCurrencyForm;