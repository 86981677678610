import { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { END_POINT_OPERATION } from '../Axios/ENDPOINTS';

export interface AllClientsProps {
  id: number,
  name: string
}

export const useGetOperationType = () => {
    
    const { data, error, mutate }:{
      data: AllClientsProps[], 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([`${END_POINT_OPERATION}type/get_all`])   
  
    return {
      data,
      mutate,
      error
    };
};