import React, { useEffect, useState } from "react";
import './styles.scss';
import { Form, Input, Modal, Select } from "antd";
import { CashDeskFormProps } from "./types";
import { useUsers } from "../../../Services/useUsers";
import { useCashDesk } from "../../../Services/useCashDesk";
import { useGetBranch } from "../../../Services/useGetBranch";
import { useGetCurrency } from "../../../Services/useGetCurrency";

export interface FilterValues{
    value: string,
    id: string,
    key: string,
  }
export interface CashDeskValuesForm {
    name: string,
    description: string,
    branch: FilterValues,
    currency: FilterValues,
    balance: string, 
    type: FilterValues
}

export const CashDeskForm: React.FC<CashDeskFormProps> =({
    openForm,
    callback,
    rowSelect,
    form_cash_desk
})=>{

    
    const [loadingBtn, setLoadingBtn] = useState(false)
    const { createCashDesk, editCashDesk} = useCashDesk()
    const { Option} = Select

    const { data: branch }= useGetBranch()
    const { data: currency} = useGetCurrency()

    useEffect(()=>{
        form_cash_desk.resetFields()
    },[])

    const handleCancel =()=>{
        callback()
    }

    const handleSave = async (values: CashDeskValuesForm)=>{
        const newValues = {
            name: values.name,
            description: values.description,
            branch_id: values.branch.key,
            currency_id: values.currency.key,
            balance: values.balance,
            type_id: values.type.key
        }
        
        if(rowSelect){
            await editCashDesk(handleCancel, rowSelect.id, newValues)
        }else{
            await createCashDesk(handleCancel, newValues)
        }
    }    

    let typeCash = [
        {
            label:'Efectivo',
            id: 1
        },
        {
            label: 'Bancaria',
            id: 2
        }
    ]

    return(
        <div>
            <Modal
                title= {rowSelect ? 'Editar Caja' : 'Crear Caja'}
                open={openForm}
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={()=>form_cash_desk.submit()}
            >
                <Form
                    form={form_cash_desk}
                    id='form_cash_desk'
                    onFinish={handleSave}
                    layout="vertical"
                    
                >
                    <Form.Item
                        label='Nombre'
                        name='name'
                        initialValue={rowSelect?.name}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                            <Input
                                placeholder="Nombre"
                            />

                    </Form.Item>                                                   
                    <Form.Item
                        label='Sucursal'
                        name='branch'
                        initialValue={{
                            value: rowSelect?.branch_name,
                            label: rowSelect?.branch_name,
                            key: rowSelect?.branch_id
                        }}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Select
                            labelInValue                                             
                        >
                            {branch && branch.map((item) => (
                                <Option value={item.name} key={item.id}>
                                    {item.name}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>  
                    <Form.Item
                        label='Moneda'
                        name='currency'                        
                        initialValue={{
                            value: rowSelect?.currency_name,
                            label: rowSelect?.currency_name,
                            key: rowSelect?.currency_id,
                        }}
                    >
                         <Select
                            labelInValue                                             
                        >
                            {currency && currency.map((item) => (
                                <Option value={item.name} key={item.id}>
                                {item.name}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>   
                    <Form.Item
                        label='Balance'
                        name='balance'
                        initialValue={rowSelect?.balance}
                    >
                        <Input
                            type= 'number'
                        />
                    </Form.Item> 
                    <Form.Item
                        label='Tipo de caja'
                        name='type'                        
                        initialValue={{
                            // value: rowSelect?.type_id && typeCash.map((item)=>item.id.toString() == rowSelect?.type_id).,
                            // label:  rowSelect?.type_id == '1' ? 'Efectivo' : 'Bancaria',
                            key: rowSelect?.type_id,
                        }}
                    >
                         <Select
                            labelInValue                                             
                        >
                            {typeCash.map((item) => (
                                <Option value={item.label} key={item.id}>
                                {item.label}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>  
                    <Form.Item
                        label='Descripción'
                        name='description'
                        initialValue={rowSelect?.description}
                    >
                        <Input
                            placeholder="Descripción"
                        />
                    </Form.Item> 
                </Form>
            </Modal>
        </div>
    )
}

export default CashDeskForm;