import { useContext, useEffect } from 'react';
import useSWR, { KeyedMutator } from "swr";

import { useAuthSWR } from './useAuthSWR';
import { END_POINT_CURRENCY_GET_ALL } from '../Axios/ENDPOINTS';

export interface CurrencyProps {
  id: number,
  name: string,
  sign: string
}


export interface ResponseGetUsers{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: CurrencyProps[]  
}

export const useGetCurrency = () => {
    
    const {data, error, mutate }:{
      data: CurrencyProps[], 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([END_POINT_CURRENCY_GET_ALL])    

  
    return {
      data,
      mutate,
      error
    };
};