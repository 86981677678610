import React, { useContext, useEffect, useState } from 'react';
import './styles.scss'
import {  Button, Col, Modal, Row, Table } from 'antd';
import {
    DeleteOutlined,
    PlusCircleOutlined,
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { UsersSectionProps } from './types';
import UserForm   from '../../Forms/UserForm';
import { CashDeskProps, useGetCashDesk } from '../../../Services/useGetCashDesk';
import { useCashDesk } from '../../../Services/useCashDesk';
import CashDeskForm, { CashDeskValuesForm } from '../../Forms/CashDeskForm';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';


export interface DataType {
  index: number,
  id: number,
  name: string,
  description: string,
  branch_id: string,
  branch_name: string,
  currency_id: string,
  currency_sign: string,
  currency_name: string,
  balance: string, 
  type_id: string,
  type_name: string
}

const CashDeskSection: React.FC<UsersSectionProps> = () => {

    const {data: dataApi} = useGetCashDesk()    
    const {deleteCashDesk} = useCashDesk()
    const {getCheckout} = useCashDesk()
    
  const {adjustedTableHeigthScroll} = useContext(StylesContext) 

    const [form_cash_desk] = useForm()
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [openForm, setOpenForm] = useState<boolean>(false)
    const [rowSelect, setRowSelect] =useState<DataType | undefined>()    
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    

    useEffect(()=>{
      setLoadingTableData(true)
      if(dataApi){
          rowData() 
          setLoadingTableData(false)
      }
  },[dataApi])  

  const rowData = (data? : CashDeskProps[]) =>{
      let filterData: DataType[]= []
      let list: CashDeskProps[] = data ? data : dataApi
    
      if(list && list){
        list.map((data, index)=>{            
          filterData.push({
            index: index,
            id: data.id,
            name: data.name,
            description: data.description,
            branch_id: data.branch.id.toString(),
            branch_name: data.branch.name,
            currency_sign: data.currency.sign,
            currency_id: data.currency.id.toString(),
            currency_name: data.currency.name,
            balance: data.balance.toString(), 
            type_id: data.type?.id?.toString() || '', //1 efectivo, 2 bancaria
            type_name: data.type.name || ''
          })
        })                  
      }       
      setDataTable(filterData)
    }

    const callback = () =>{
        setLoadingTableData(false)
        setRowSelect(undefined)
        setOpenForm(false)
        setShowConfirmDelete(false)
    }

    const handleRenderer = (text: string, record: DataType) =>{
      return (
          <div className='cash_desk_action_action_render'>
              <DeleteOutlined 
                  onClick={()=>{
                      setRowSelect(record)
                      setShowConfirmDelete(true)
                  }}
                  style={{marginLeft:'30px', color: '#37bde0'}} /> 
          </div>  
      )      
    }
    const ModalConfirmDelete = () => {
      return(
          <Modal
              title="Eliminar"
              open={showConfirmDelete}
              onOk={()=>{
                  if(rowSelect && rowSelect.id){
                      deleteCashDesk(callback, rowSelect.id)
                  }}}
              onCancel={()=>setShowConfirmDelete(false)}
              okText="Eliminar"
              cancelText="Cancelar"
          >
              <p>¿Estás seguro de que desea eliminar {rowSelect?.description}?</p>
          </Modal>
      )
  }

  const filters = (type: 'name' | 'branch_name' ) => {
    let textAndValue: { text: string, value: string }[] = [];
    let uniqueValues = new Set();

    dataApi?.forEach((item) => {
        const code = type == 'name' ? item.name : item.branch.name

        if (!uniqueValues.has(code)) {
            uniqueValues.add(code);
            textAndValue.push({
                text: code,
                value: code
            });
        }
    });
    return textAndValue;
  }
  

  const renderType = (value: string)=>{
    let type = ''
    switch(value){
      case '1':
        type = 'Efectivo'
        break;
      case '2':
        type = 'Bancaria'
        break;
      default:
        type = ''
        break
    }
    return type
  } 
    
  const columns: ColumnsType<DataType> = [
    {
      title: 'Caja',
      dataIndex: 'name',
      key: '1',
      className:'cursor-pointer',
      filters: filters('name'),
      onFilter: (value, record) => typeof value === 'string' && record.name.includes(value),
      filterSearch: true,
      onCell: (record, rowIndex) => {
          return {
            onClick: () => {
              onClickRow(record)
            }
          };
        }
    },
    {
      title: 'Tipo de caja',
      dataIndex: 'type_name',
      key: '2',
      className:'cursor-pointer',
      onCell: (record, rowIndex) => {
          return {
            onClick: () => {
              onClickRow(record)
            }
          };
        }
    },
    {
      title: 'Sucursal',
      dataIndex: 'branch_name',
      key: '3',
      className:'cursor-pointer',
      filters: filters('branch_name'),
      onFilter: (value, record) => typeof value === 'string' && record.branch_name.includes(value),
      filterSearch: true,
      onCell: (record, rowIndex) => {
          return {
            onClick: () => {
              onClickRow(record)
            }
          };
        }
    },
    {
        title: 'Moneda',
        dataIndex: 'currency_sign',
        render:(text, record) =><p style={{margin: '0px', padding:'0px'}}>{record.currency_name} / {text}</p>,            
        key: '4',
        className:'cursor-pointer',
        ellipsis: true,
        onCell: (record, rowIndex) => {
            return {
              onClick: () => {
                onClickRow(record)
              }
            };
          }
    },
    {
        title: 'Balance',
        dataIndex: 'balance',
        key: '5',
        className:'cursor-pointer',
        ellipsis: true,
        onCell: (record, rowIndex) => {
            return {
              onClick: () => {
                onClickRow(record)
              }
            };
          }
    },  
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: '6',
      className:'cursor-pointer',
      onCell: (record, rowIndex) => {
          return {
            onClick: () => {
              onClickRow(record)
            }
          };
        }
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      render:  handleRenderer,
      key: 'id',
      width: 110,      
    }, 
  ];
    
    const onClickRow = (record: DataType) =>{         
      setRowSelect(record)
        setOpenForm(true) 
    }  
    

    return( 
        <>
          <ModalConfirmDelete/>
          { openForm && <CashDeskForm
              openForm={openForm} 
              callback={callback}
              rowSelect={rowSelect}
              form_cash_desk={form_cash_desk}
          /> }     
          <div className='cash_desk_section cash_desk_section_container'>                                  
              <Row
                  className='global_title_section'  
              >
                  <h3>CAJAS</h3>                
              </Row>
              <Row 
                className='global_button_box'
              >
                  <Button
                    type='primary'
                    className='movements_section_btn'
                    onClick={()=>{setOpenForm(true)}}
                    >
                      Nueva caja
                  </Button>                  
              </Row>
              <Row 
                style={{
                    display:'flex',
                    justifyContent: 'center',
                    width: '100%',
                    height: '95%',
                    padding: '0px 30px',                        
                    border: '1px solid   #645fca6c'
                }}
              >
                {
                  loadingTableData
                  ? <SpinerHorizontal/> 
                  : <Table 
                      style={{
                          width: '100%',
                          marginTop: '1%'
                      }}
                          columns={columns} 
                          dataSource={dataTable}  
                          scroll={{ y: 580 }}                                   
                          pagination={{
                              pageSize: 50,
                              showSizeChanger: false,                                      
                          }}
                      />    
                  } 
              </Row>             
          </div>
        </>
        
        
)};

export default CashDeskSection;