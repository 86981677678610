import React, { useContext } from 'react';
import './styles.scss'
import { Col, Row, Table} from 'antd';
import {
    PlusCircleOutlined,
  } from '@ant-design/icons';
import { StylesContext } from '../../Contexts/UserContext/StylesContext';


const Home: React.FC = () => {

    const {adjustedTableHeigthScroll} = useContext(StylesContext)
  
    return( 
        <>       
        <div className='home_container'>
           
            <Row
                 style={{                    
                    display: 'flex', 
                    alignItems: 'center',
                    width: '100%',
                    borderBottom: '1px solid white'
                }}
            >
                <h2>Titulo</h2>                
            </Row>
            <Row 
                style={{
                    width: '100%',
                    marginTop: '20px',
                    paddingLeft:'30px',
                    marginLeft: '5px',
                }}
            >
                <Col 
                    span={2}
                >
                    <h4
                    >Nuevo formulario</h4>
                </Col>
                <Col 
                    span={1} 
                    style={{
                        display: 'flex', 
                        alignItems: 'center',
                    }}>
                        <PlusCircleOutlined style={{color:'#0072ce', fontSize: '15px'}}/>
                </Col>
                <Col span={19}>
                </Col>
            </Row>
            <Row 
                style={{
                    display:'flex',
                    justifyContent: 'center',
                    width: '100%',
                    height: '70%',
                    padding: '0px 30px',
                    
                    border: '1px solid  #1e2957'
                }}>
                <Table
                    style={{
                        width: '80%',
                        marginTop: '7%'
                    }}
                >

                </Table>
            </Row>             
        </div>
        </>
        
        
)};

export default Home;