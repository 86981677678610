import React, { useContext, useState } from 'react';
import {
  ContainerOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
  SwapOutlined,
  UserSwitchOutlined,
  DollarOutlined,
  TruckOutlined  
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import {   Menu as MenuAntd} from 'antd';
import './styles.scss'
import { useNavigate } from 'react-router';
import { UserContext } from '../../Contexts/UserContext/UsersContext';

type MenuItem = Required<MenuProps>['items'][number];

const Menu: React.FC = () => {

  const [collapsed, setCollapsed] = useState(true);
  
  const navigate = useNavigate();

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('Menu', 'collapsed', collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />),//<PieChartOutlined style={{fontSize: ' 15px'}} />  
  getItem('Saldos de Cajas', 'balance_sheets',  <TruckOutlined  style={{fontSize: 20}}/>),
  getItem('Movimientos de cajas', 'cash_movements',  <SwapOutlined />),  
  getItem('Movimientos de cuentas', 'account_movements',  <UserSwitchOutlined style={{fontSize: 18}}/>),
  getItem('Operaciones', 'operations', <DollarOutlined style={{fontSize: 17}}/>, 
    // [
    //   getItem('Depósito / Extracción', 'extraction_deposit'),
    //   getItem('Compra / Venta moneda', 'purchase_sale_currency'),
    //   getItem('Depósito / Extracción banco', 'extraction_deposit_banc'),
    // ]
  ),
  getItem('Administración', 'admin', <ContainerOutlined />, [
    getItem('Usuarios', 'user'),
    getItem('Moneda', 'currency'),
    getItem('Tipo de cambio', 'exchange_rate'),
    getItem('Sucursales', 'branch'),
    getItem('Cajas', 'checkout'),
    getItem('Clientes', 'customers'),
  ]),
];

const onClick: MenuProps['onClick'] = (e) => {
    switch(e.key){
      case 'collapsed':  
        toggleCollapsed();
        break;
      case 'account_movements':       
        navigate('/dashboard/account_movements');
        break;
      case 'cash_movements':       
        navigate('/dashboard/cash_movements');
        break;  
      case 'extraction_deposit':       
        navigate('/dashboard/extraction_deposit');
        break;
      case 'purchase_sale_currency':       
        navigate('/dashboard/purchase_sale_currency');
        break;  
      case 'extraction_deposit_banc':       
        navigate('/dashboard/extraction_deposit_banc');
        break;          
      case 'user':       
        navigate('/dashboard/user');
        break;
      case 'currency':       
        navigate('/dashboard/currency');
        break;  
      case 'branch':       
        navigate('/dashboard/branch');
        break;   
      case 'checkout':       
        navigate('/dashboard/checkout');
        break;     
      case 'exchange_rate':       
        navigate('/dashboard/exchange_rate');
        break;  
      case 'operations':       
        navigate('/dashboard/operations');
        break;   
      case 'balance_sheets':       
        navigate('/dashboard/balance_sheets');
        break; 
      case 'customers':       
        navigate('/dashboard/customers');
        break;    
      default:
        break;   
    }
  };
  
  return (
    <div  className='menu_container'>
        <MenuAntd
            style={{
                zIndex: 200,
                borderRadius: '7px',
                width: collapsed ? '72px' : '256px'
            }}
            onClick={onClick}
            defaultSelectedKeys={['1']}
            defaultOpenKeys={[]}
            mode="inline"
            theme="dark"
            inlineCollapsed={collapsed}
            items={items}
        />
    </div>
  );
};

export default Menu;