import React, { useEffect, useState } from "react";
import './styles.scss';
import { Form, Input, InputNumber, Modal, Select } from "antd";
import { ExchangeRateFormProps } from "./types";
import { useBranch } from "../../../Services/useBranch";
import { useGetCurrency } from "../../../Services/useGetCurrency";
import { useExchangeRate } from "../../../Services/useExchangeRate";

export interface FilterValues{
    value: string,
    id: string,
    key: number,
}

export interface ExchangeRateValuesForm {
    main_currency: FilterValues,
    second_currency: FilterValues,
    sell: number,
    buy: number
}

export const ExchangeRateForm: React.FC<ExchangeRateFormProps> =({
    openForm,
    callback,
    rowSelect,
    form
})=>{

    
    const [loadingBtn, setLoadingBtn] = useState(false)
    const {createExchangeRate, editExchangeRate} = useExchangeRate()
    const { data: currency} = useGetCurrency()
    const { Option} = Select
    const [secondCurrency, setSecondCurrency] = useState<{id: number | undefined, sign: string}>({
        id: undefined,
        sign: '$'
    })

    const mandatory = [
        {
            required: true,
            message:'Este campo es obligatorio'
        }
    ]

    useEffect(()=>{
        form.resetFields()
        if(rowSelect){

            setSecondCurrency({
                id: +rowSelect.second_currency_id,
                sign: rowSelect.second_currency_name
            })
        }
    },[])

    const handleCancel =()=>{
        callback()
    }

    const handleSave = async (values: ExchangeRateValuesForm)=>{
        let newValue =
        {
            main_currency_id: values.main_currency.key,
            secondary_currency_id: values.second_currency.key,
            buy_price: values.buy,
            sell_price: values.sell
          }
        if(rowSelect){
            await editExchangeRate(handleCancel, +rowSelect.id, newValue)
        }else{
            await createExchangeRate(handleCancel, newValue)
        }
    }    

    const onChangesecondCurrency = (e: any, values: any) =>{
        setSecondCurrency({
            id: values.key,
            sign: values.sign
        })
    }

    return(
        <div>
            <Modal
                title= {rowSelect ? 'Editar tipo de cambio' : 'Crear tipo de cambio'}
                open={openForm}
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={()=>form.submit()}
            >
                <Form
                    form={form}
                    id='form'
                    onFinish={handleSave}
                    layout="vertical"
                    
                >
                    <Form.Item
                        label='Moneda principal'
                        name='main_currency'                         
                        rules={mandatory}                       
                        initialValue={rowSelect ? {
                            value: rowSelect.main_currency_sign,
                            label: rowSelect.main_currency_sign,
                            key: +rowSelect.main_currency_id,
                        }: undefined}
                    >
                         <Select
                            labelInValue     
                            placeholder='Moneda'                                                                   
                        >
                            {currency && currency.map((item) => (
                                <Option value={item.name} key={item.id} >
                                {item.name}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>  
                    <Form.Item
                        label='Moneda secundaria'
                        name='second_currency'  
                        rules={mandatory}                       
                        initialValue={rowSelect ?{
                            value: rowSelect.second_currency_name,
                            label: rowSelect.second_currency_name,
                            key: +rowSelect.second_currency_id,
                        }: undefined}
                    >
                         <Select
                            labelInValue   
                            placeholder='Moneda' 
                            onChange={onChangesecondCurrency}                                         
                        >
                            {currency && currency.map((item) => (
                                <Option value={item.name} key={item.id} sign={item.sign}>
                                {item.name}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>  
                    <Form.Item
                        label='Precio venta'
                        name='sell'
                        initialValue={rowSelect?.sell && +rowSelect?.sell}
                        rules={mandatory} 
                    >
                        <InputNumber
                            addonBefore={secondCurrency.sign}
                        />
                    </Form.Item> 
                    <Form.Item
                        label='Precio compra'
                        name='buy'
                        initialValue={rowSelect?.buy && +rowSelect?.buy}
                        rules={mandatory} 
                    >
                        <InputNumber
                            addonBefore={secondCurrency.sign}
                        />
                    </Form.Item>                  
                </Form>
            </Modal>
        </div>
    )
}

export default ExchangeRateForm;