import React, { useContext, useEffect, useState } from 'react';
import './styles.scss'
import {  Button, Col, Modal, Row, Table } from 'antd';
import {
    DeleteOutlined,
    PlusCircleOutlined,
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { UsersSectionProps } from './types';
import  { UserValuesForm }  from '../../Forms/UserForm';
import CurrencyForm from '../../Forms/CurrencyForm';
import { useBranch } from '../../../Services/useBranch';
import { BranchProps, useGetBranch } from '../../../Services/useGetBranch';
import BranchForm from '../../Forms/BranchForm';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';


export interface DataType {
  index: number,
  id: number,
  name: string,
  address: string,
  city: string,
  // cash_desks_id: string,
  // cash_desks_description: string,
  // currency_name: string,
  // currency_sign: string,
  // balance: string
}


const BranchSection: React.FC<UsersSectionProps> = () => {

    const {data: dataApi } = useGetBranch()
    
  const {adjustedTableHeigthScroll} = useContext(StylesContext) 
    
    const {deleteBranch} = useBranch()

    const [form_branch] = useForm()
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [openForm, setOpenForm] = useState<boolean>(false)
    const [rowSelect, setRowSelect] =useState<DataType | undefined>()    
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    useEffect(()=>{
      setLoadingTableData(true)
      if(dataApi){
          rowData() 
          setLoadingTableData(false)
      }
  },[dataApi])  

  const rowData = (data? : BranchProps[]) =>{
      let filterData: DataType[]= []
      let list: BranchProps[] = data ? data : dataApi
      let values: UserValuesForm = form_branch.getFieldsValue() 
    
      if(list && list){
        list.map((data, index)=>{            
          filterData.push({
            index: index,
            id: data.id,
            name: data.name,
            address: data.address,
            city: data.city,
            // cash_desks_id: data.cash_desks[0].id.toString(),
            // cash_desks_description: data.cash_desks[0].description,
            // currency_name: data.cash_desks[0].currency.name,
            // currency_sign: data.cash_desks[0].currency.sign,
            // balance: data.cash_desks[0].balance.toString()
          })
        })                  
      }       
      setDataTable(filterData)
    }

    const callback = () =>{
        setLoadingTableData(false)
        setRowSelect(undefined)
        setOpenForm(false)
        setShowConfirmDelete(false)
    }

    const handleRenderer = (text: string, record: DataType) =>{
      return (
          <div className='currency_action_action_render'>
              <DeleteOutlined 
                  onClick={()=>{
                      setRowSelect(record)
                      setShowConfirmDelete(true)
                  }}
                  style={{marginLeft:'30px', color: '#37bde0'}} /> 
          </div>  
      )      
    }
    const ModalConfirmDelete = () => {
      return(
          <Modal
              title="Eliminar"
              open={showConfirmDelete}
              onOk={()=>{
                  if(rowSelect && rowSelect.id){
                      deleteBranch(callback, rowSelect.id)
                  }}}
              onCancel={()=>setShowConfirmDelete(false)}
              okText="Eliminar"
              cancelText="Cancelar"
          >
              <p>¿Estás seguro de que desea eliminar {rowSelect?.name}?</p>
          </Modal>
      )
  }


    
    const columns: ColumnsType<DataType> = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: '1',
            className:'cursor-pointer',
            onCell: (record, rowIndex) => {
                return {
                  onClick: () => {
                    onClickRow(record)
                  }
                };
              }
        },
        {
            title: 'Dirección',
            dataIndex: 'address',
            key: '2',
            className:'cursor-pointer',
            onCell: (record, rowIndex) => {
                return {
                  onClick: () => {
                    onClickRow(record)
                  }
                };
              }
        },        
        {
          title: 'Localidad',
          dataIndex: 'city',
          key: '3',
          className:'cursor-pointer',
          onCell: (record, rowIndex) => {
              return {
                onClick: () => {
                  onClickRow(record)
                }
              };
            }
        },
        {
          title: 'Acciones',
          dataIndex: 'id',
          className:'cursor-pointer',
          render:  handleRenderer,
          key: '4',
          width: 110,      
      }, 
    ];
    
    const onClickRow = (record: DataType) =>{         
      setRowSelect(record)
        setOpenForm(true) 
    }  
    

    return( 
        <>
          <ModalConfirmDelete/>
          { openForm && <BranchForm
              openForm={openForm} 
              callback={callback}
              rowSelect={rowSelect}
              form_branch={form_branch}
          /> }     
          <div className='currency_section currency_section_container'>                                  
          <Row
                 className='global_title_section'  
              >
                  <h3>SUCURSALES</h3>                
              </Row>
              <Row 
                className='global_button_box'
              >
                <Button
                  type='primary'
                  className='movements_section_btn'
                  onClick={()=>{setOpenForm(true)}}
                  >
                    Nueva sucursal
                </Button>                
              </Row>
              <Row 
                style={{
                    display:'flex',
                    justifyContent: 'center',
                    width: '100%',
                    height: '80%',
                    padding: '0px 30px',                        
                    border: '1px solid   #645fca6c'
                }}
              >
                {
                  loadingTableData
                  ? <SpinerHorizontal/> 
                  : <Table 
                      style={{
                          width: '80%',
                          marginTop: '2%'
                      }}
                      columns={columns} 
                      dataSource={dataTable}  
                      scroll={{ y: adjustedTableHeigthScroll }}                                   
                      pagination={{
                          pageSize: 50,
                          showSizeChanger: false,                                      
                      }}
                    />    
                  } 
              </Row>             
          </div>
        </>
        
        
)};

export default BranchSection;