import { notification } from 'antd';
import { axiosBackEndInstance } from '../Axios';
import { END_POINT_AUTH_LOGIN } from '../Axios/ENDPOINTS';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { useErrorLoguin } from '../hooks/useErrorLoguin';
import { useContext } from 'react';
import { UserContext } from '../Contexts/UserContext/UsersContext';

export interface LoguinProps{
  status: number,
  data:{    
    access_token: string
    refresh_token: string,
  }
}


export const useLogin= () => {

    const {errorHandling} = useErrorLoguin()
    const {setUnlog} = useContext(UserContext)
     
    const loginRequest = async (
      callback: Function,
      login_data:{
        username: string,
        password: string,
      }
      
      ): Promise<LoguinProps | undefined> => {      
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_AUTH_LOGIN, login_data
      )
      callback()
      setUnlog(true)
      return data
    } catch (newError) {
      callback()
      const status =  await errorHandling(newError).request.status      
      console.log('status ' + status + ' ' + newError);
      
    }
  };

  return {
    loginRequest
  };
};
