import { Table } from 'antd'
import './styles.scss'
import { DepositExtractionBoxProps } from './types'
import { ColumnsType } from 'antd/es/table'
import { CashDeskProps } from '../../../Services/useGetCashDesk'
import { useContext, useEffect, useState } from 'react'
import { useCashDesk } from '../../../Services/useCashDesk'
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal'
import { BalancesContext, DataType } from '../../../Contexts/UserContext/BalancesContext'


export const DashboardBox: React.FC<DepositExtractionBoxProps> = ({

}) =>{

  const { dataTable, setDataTable, mutateBalance, setMutateBalance } = useContext(BalancesContext) 
  const { getAllCheckout, } = useCashDesk()

  const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
   
  useEffect(()=>{
    handleSearch(false)
    if(mutateBalance){
      setMutateBalance(false)
    }
  },[mutateBalance])

  const rowData = (data: CashDeskProps[]) =>{
      let filterData: DataType[]= []
      let list: CashDeskProps[] = data 
    
      if(list && list){
        list.map((data, index)=>{            
          filterData.push({
            index: index,
            id: data.id,
            checkout: data.name,
            balance: data.balance.toString(),
            future_balance: data.future_balance.toString(),
          })
        })        
          
      }       
      setDataTable(filterData)
    }
   
    const columns: ColumnsType<DataType> = [
        {
            title: 'Caja',
            dataIndex: 'checkout',
            key: '1',
            className:'cursor-pointer',
        },
        {
            title: 'Saldo actual',
            dataIndex: 'balance',
            render:(text)=><div
              style={{
                color: Number(text) < 0 ? 'red' : '#ADADAD'
              }}
            >{ new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(text)}</div>,  
            key: '4',
        },
        {
            title: 'Saldo Futuro',
            dataIndex: 'future_balance',
            render:(text, record)=><div
              style={{
                color:Number(record.balance) > Number(text) ? 'red' : Number(record.balance) < Number(record.future_balance) ? '#abfd2e' : '#ADADAD'
              }}
            >{new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(text)}</div>,
            key: '5',
            className:'cursor-pointer',
            ellipsis: true,           
        },       
    ];    
 

    const handleSearch = async(loading: boolean) =>{
      if(loading){
        setLoadingTableData(true)
      }      
      const data = await getAllCheckout(setLoadingTableData) 
      if(data){
        rowData(data)
      }       
    }       

    return(
        <div className='dashboard-box'>
            <p>SALDO DE CAJAS</p>
            <div 
                className='dashboard-box_container'  
                style={{
                    marginTop:'50px'
                }}          
            >  
            <div
              style={{
                padding: '5px 15px'
              }}
            >
              {
                !loadingTableData ?
                <Table 
                style={{
                    width: '100%',
                    marginTop: '1%'
                }}
                    columns={columns} 
                    dataSource={dataTable}                                
                    pagination={false}
                /> :
                <SpinerHorizontal/>            
              }
            </div>                                                               
            </div>   
        </div>
         
    )
}