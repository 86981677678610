import React, { useContext, useState } from 'react';
import './styles.scss'
import { Row} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useGetProfile } from '../../../Services/useGetProfile';
import { UserContext } from '../../../Contexts/UserContext/UsersContext';
import {
    UserOutlined
  } from '@ant-design/icons';

const ProfileSections: React.FC = () => {

    const {userData} = useContext(UserContext)

    const [showForm, setShowForm] = useState(false)

    const styleProfileData ={
        display: 'flex',
        color: 'white'        
    }

    const handleCancelForm =()=>{
        setShowForm(false)
    }

  return( 
    <>  
        <div className='profile_section_container'>                                          
            <Row
                className='global_title_section' 
            >
                <h3> <UserOutlined style={{fontSize: 18, marginRight: '10px', color: '#37bde0'}}/>PERFIL </h3>      
            </Row>             
            <Row 
                className='profile_section_data'
                style={{
                    display:'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '88%',
                    marginTop: '15px',                  
                    border: '1px solid  #467ef85e',
                    padding: '40px'
                }}
            >
                <div
                    style={styleProfileData}
                > 
                    <h4>USUARIO: </h4> 
                    <p>{userData?.first_name} {userData?.last_name}</p>
                </div>  
                <div
                    style={styleProfileData}
                > 
                    <h4>EMAIL:</h4>
                    <p>{userData?.email} </p>
                </div>  
                <div
                    style={styleProfileData}
                > 
                    <h4>ROL: </h4>
                    <p>{userData?.role}</p>
                </div>                                   
            </Row>     
                  
        </div>
    </>        
  )};

export default ProfileSections;