import React, { useEffect, useState } from "react";
import './styles.scss';
import { Form, Input, Modal } from "antd";
import { CurrencyFormProps } from "./types";
import { useBranch } from "../../../Services/useBranch";

export interface FilterValues{
    value: string,
    id: string,
    key: number,
}

export interface BranchValuesForm {
    name: string,
    address: string
    city: string
}

export const BranchForm: React.FC<CurrencyFormProps> =({
    openForm,
    callback,
    rowSelect,
    form_branch
})=>{

    
    const [loadingBtn, setLoadingBtn] = useState(false)
    const {createBranch, editBranch} = useBranch()

    useEffect(()=>{
        form_branch.resetFields()
    },[])

    const handleCancel =()=>{
        callback()
    }

    const handleSave = async (values: BranchValuesForm)=>{
        if(rowSelect){
            await editBranch(handleCancel, rowSelect.id, values)
        }else{
            await createBranch(handleCancel, values)
        }
    }    

    return(
        <div>
            <Modal
                title= {rowSelect ? 'Editar sucursal' : 'Crear sucursal'}
                open={openForm}
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={()=>form_branch.submit()}
            >
                <Form
                    form={form_branch}
                    id='form_branch'
                    onFinish={handleSave}
                    layout="vertical"
                    
                >
                    <Form.Item
                        label='Nombre'
                        name='name'
                        initialValue={rowSelect?.name}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                            <Input
                                placeholder="Nombre"
                            />

                    </Form.Item>                       
                    <Form.Item
                        label='Dirección'
                        name='address'
                        initialValue={rowSelect?.address}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Input
                            placeholder="Ej: Ricardone 145"
                        />
                    </Form.Item>      
                    <Form.Item
                        label='Localidad'
                        name='city'
                        initialValue={rowSelect?.city}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Input
                            placeholder="ciudad / localidad"
                        />
                    </Form.Item>                    
                </Form>
            </Modal>
        </div>
    )
}

export default BranchForm;