import React, { useContext, useState } from 'react';
import './styles.scss'
import Menu from '../../Components/Menu';
import Navbar from '../../Components/Navbar';
import BodyContent from '../../Components/BodyContent';
import { StylesContext } from '../../Contexts/UserContext/StylesContext';
import Filters from '../../Components/Filters';
import { DashboardBox } from '../../Components/Filters-Boxs/DashboardBox';
import { MenuBox } from '../../Components/Filters-Boxs/MenuBox';
import { VariablesContext } from '../../Contexts/UserContext/VariablesContext';
import DepositExtractionForm from '../../Components/Forms/DepositExtractionForm';
import { useForm } from 'antd/es/form/Form';
import TransfersBetweenAccounts from '../../Components/Forms/TransfersBetweenAccounts';
import PucrchaseSaleCurrencyForm from '../../Components/Forms/PucrchaseSaleCurrencyForm';
import TransfersBetweenBoxes from '../../Components/Forms/TransfersBetweenBoxes';
import Windows from '../../Components/Forms/Windows';
import USDT from '../../Components/Forms/USDT';
import TransfersSuppliers from '../../Components/Forms/TransfersSuppliers';


export const Dashboard: React.FC =()=>{

  const {dashboard_body} = useContext(StylesContext)
  const {setShowForms, showForms} = useContext(VariablesContext)
  const [ form_deposit_extraction ] = useForm()
  const [ form_transferers ] = useForm()
  const [ form_buy_and_sell ] = useForm()
  const [ form_windows ] = useForm()
  const [ form_usdt ] = useForm()
  const [ menuBalances, setMenuBalances]= useState(false)
  const [ menuForms, setMenuForms]= useState(false)

  return(
    <div className="dashboard_container">   
      { showForms.windows &&
        <Windows
          form_buy_and_sell={form_windows}
        />
      } 
       { showForms.usdt &&
        <USDT
          form_buy_and_sell={form_usdt}
        />
      } 
      { showForms.deposit_extraction &&
        <DepositExtractionForm
          form={form_deposit_extraction}
        />
      }
      { showForms.transferer && 
        <TransfersBetweenAccounts
          form={form_transferers}
        />
      }    
      { showForms.transferer_to_suppliers && 
        <TransfersSuppliers
          form={form_transferers}
        />
      } 
      { showForms.buy_and_sell &&
        <PucrchaseSaleCurrencyForm
          form_buy_and_sell={form_buy_and_sell}
        />
      } 
      { showForms.transfers_between_cashs && 
        <TransfersBetweenBoxes
          form={form_transferers}
        />
      }   
      { showForms.transfer_to_suppliers && 
        <TransfersSuppliers
          form={form_transferers}
        />
      }   
      <Filters 
        components={<DashboardBox/>} 
        height={0} 
        color={'rgba(255, 0, 0, 0.555)'} 
        detailsColor={'red'}
        tooltip='Saldos de Cajas'
        collapsedFilsters={menuBalances}
        setCollapsedFilters={setMenuBalances}
        marginTop={0}  
        widhtSections={600}      
      />
      <Filters 
        components={
          <MenuBox 
            setShowForms={setShowForms}  
          />        
        }
        height={60}
        marginTop={60}
        tooltip='Formularios'
        collapsedFilsters={menuForms}
        setCollapsedFilters={setMenuForms}
      />      
      <Navbar/>    
      <div className='dashboard_body'
        style={{
          height: `${dashboard_body}px`
        }}
      >
        <Menu/>
        <BodyContent/>
      </div>       
    </div>
  )
}