import React, {
    createContext,
    useEffect,
    useState,
  } from 'react';
import { CheckoutBalance } from '../../Services/useCashDesk';
import { DataType } from './MovementAcountContext';

  class VoidContext implements MovementCashContext {
    get dataTable(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setDataTable(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get mutate(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setMutate(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get showingCash(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setShowingCash(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get balance(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setBalance(): never {
      throw new Error('Cannot consume context outside of provider');
    }
  }

  interface MovementCashContext {
    dataTable: DataType[], 
    setDataTable: Function,
    mutate: boolean 
    setMutate: Function,
    showingCash:{
      name: string,
      id: number
    } | undefined, 
    balance: CheckoutBalance | undefined,
    setBalance: Function
    setShowingCash: Function
  }

  interface propsWithChildren {
    children: React.ReactNode
  }
  
  export const MovementCashContext = createContext<MovementCashContext>(new VoidContext());
  export const MovementCashContextProvider= ({ children }: propsWithChildren): JSX.Element => {

    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [mutate, setMutate] = useState<boolean>(false)
    const [balance, setBalance] = useState<CheckoutBalance | undefined>()

    const [ showingCash, setShowingCash ] = useState<{
      name: string,
      id: number,
    } | undefined>()

 
    return (
      <MovementCashContext.Provider value={{
        dataTable, 
        setDataTable,
        mutate, 
        setMutate,
        showingCash, 
        setShowingCash,
        balance, 
        setBalance

      }}>{children}</MovementCashContext.Provider>
    );
  };



  