import React, { useEffect, useState } from "react";
import './styles.scss';
import { Checkbox, Form, Input, InputNumber, Modal, Select } from "antd";
import { CustomerFormProps } from "./types";
import { useCustomers } from "../../../Services/useCustomers";

export interface FilterValues{
    value: string,
    id: string,
    key: string,
  }
export interface customerValuesForm {
    first_name: string,
    last_name: string
    phone_number: number,
    email: string,
    is_supplier: string,
    is_customer: string
}

export const CustomerForm: React.FC<CustomerFormProps> =({
    openForm,
    callback,
    rowSelect,
    form_customer
})=>{
    
    const [loadingBtn, setLoadingBtn] = useState(false)
    const { createCustomers, editCustomers} = useCustomers()
    const [isSupplier, setIsSupplier]= useState(false)
    const [isCustomer, setIsCustomer]= useState(false)
    const { Option} = Select

    useEffect(()=>{
        if(rowSelect){
            if(rowSelect.is_supplier == 'true'){ setIsSupplier(true) }
            if(rowSelect.is_customer == 'true'){setIsCustomer(true)}                      
        }else{
            form_customer.resetFields()
            setIsCustomer(false)
            setIsCustomer(false)
        }
    },[])

    const handleCancel =()=>{
        callback()
    }

    const handleSave = async (values: customerValuesForm)=>{
        const newValues = {
            first_name: values.first_name,
            last_name: values.last_name,
            phone_number: values?.phone_number?.toString() || '',
            email: values?.email || '',
            is_supplier: isSupplier,
            is_customer: isCustomer
        }
        
        if(rowSelect){
            await editCustomers(handleCancel, +rowSelect.id, newValues)
        }else{
            await createCustomers(handleCancel, newValues)
        }
    }    

    return(
        <div>
            <Modal
                title= {rowSelect ? 'Editar Cliente' : 'Crear Cliente'}
                open={openForm}
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={()=>form_customer.submit()}
            >
                <Form
                    form={form_customer}
                    id='form_customer'
                    onFinish={handleSave}
                    layout="vertical"
                    
                >
                    <Form.Item
                        label='Nomber'
                        name='first_name'
                        initialValue={rowSelect?.first_name}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                            <Input
                                placeholder="Nombre"
                            />

                    </Form.Item>   
                    <Form.Item
                        label='Apellido'
                        name='last_name'
                        initialValue={rowSelect?.last_name}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                            <Input
                                placeholder="Apellido"
                            />

                    </Form.Item> 
                    <Form.Item
                        label='Celular'
                        name='phone_number'
                       
                        initialValue={rowSelect?.phone_number}
                    >
                            <InputNumber
                                style={{width: '100%'}}
                                placeholder="3412326088"
                            />

                    </Form.Item>                                                   
                    <Form.Item
                        label='email'
                        name='email'
                        initialValue={rowSelect?.email}
                    >
                            <Input
                                type="e-mail"
                                placeholder="e-mail"
                            />

                    </Form.Item> 
                    <Form.Item
                        name='is_supplier'
                    >
                        <Checkbox
                            checked={isSupplier}
                            onChange={()=>setIsSupplier(!isSupplier)}
                             
                        >
                            Es Proveedor
                        </Checkbox>
                    </Form.Item>      
                    <Form.Item
                        name='is_customer'
                    >
                        <Checkbox
                            checked={isCustomer}
                            onChange={()=>setIsCustomer(!isCustomer)}
                            
                        >
                            Es Cliente
                        </Checkbox>
                    </Form.Item>             
                </Form>
            </Modal>
        </div>
    )
}

export default CustomerForm;