import { useEffect, useState } from "react";
import { useGetCurrency } from "../../../Services/useGetCurrency";
import { CashDeskProps } from "../../../Services/useGetCashDesk";
import { Checkbox, CheckboxProps, Col, Form, FormInstance, Row, Select } from "antd";
import { ResponseAccounts } from "../../../Services/useAccounts";
import { useCashDesk } from "../../../Services/useCashDesk";
import './styles.scss';
import { DataType } from "../../../Contexts/UserContext/MovementAcountContext";

export const FormItemsSell: React.FC<{
    form: FormInstance<any>
    selectedSellingCurrency:{
        id: string,
        sign: string,
        name: string
    } | undefined, 
    setSelectedSellingCurrency: Function,     
    rowSelect?: DataType
}> = ({
    form,
    selectedSellingCurrency, 
    setSelectedSellingCurrency,
    rowSelect
}) =>{
    const { Option } = Select;
    const {data: currencies} = useGetCurrency()  
    const {getCashCurrency} = useCashDesk()
    const [checkedExtraction, setCheckedExtraction] = useState(false);
    const [listCahs, setListCash] = useState<CashDeskProps[]>()
    const [spanError, setSpanError] = useState<string>()

    const mandatory = [
        {
            required: true,
            message:'Este campo es obligatorio'
        }
    ]    
    useEffect(() => {
        setSpanError(undefined)
        let extraction_cash = form.getFieldValue('extraction_cash');
        initialValuesForm()
        if(extraction_cash && extraction_cash.key){
            setCheckedExtraction(true)
        }else{
            setCheckedExtraction(false) 
            setListCash(undefined)
        }

      }, []);

    const initialValuesForm = async()=>{
        if(selectedSellingCurrency){            
            const data = await getCashCurrency(Number(selectedSellingCurrency.id)) 
            if(data){
                let is_not_bank = data.filter((x)=> x.type.id !== 2)
                setListCash(is_not_bank)
                if(data.length == 0){
                    setSpanError(`No existen cajas en ${selectedSellingCurrency.sign}. Cree una en Menú -> Administración -> Cajas`)
                }else{
                    if(spanError){
                        setSpanError(undefined)
                    }
                }
            } 
        }        
    }

    const onChangeExtraction: CheckboxProps['onChange'] =(e) => {
        setCheckedExtraction(e.target.checked);  
        form.setFieldValue('extraction_cash', undefined);                
    };   


    const onChangeCurrency = async(values: {key:string | undefined, label: string}, record: any)=>{   
        setSelectedSellingCurrency({
            id: values.key,
            sign: record?.sign ? record.sign : '',
            name: values.label 
        })
        
        form.setFieldValue('extraction_cash', undefined); 
        const data = await getCashCurrency(Number(values.key)) 
        if(data){
            let is_not_bank = data.filter((x)=> x.type.id !== 2)
            setListCash(is_not_bank)
            if(data.length == 0){
                setSpanError(`No existen cajas en ${ record?.sign ? record.sign : ''}. Cree una en Menú -> Administración -> Cajas`)
            }else{
                if(spanError){
                    setSpanError(undefined)
                }
            }
        } 
    }

 
    return(
    <div className="section_sell">   
                   
        <Form.Item
            name='sell_currency'
            className="sell_and_buy_divide "
        > 
            <div className="section_sell_title">Moneda venta</div> 
            <Select                           
                labelInValue  
                value={selectedSellingCurrency ? { 
                    value: selectedSellingCurrency?.name || '', //'Dolar
                    label: selectedSellingCurrency?.name || '', //Dolar
                    key: selectedSellingCurrency?.id || '', //2
                    sign: selectedSellingCurrency?.sign || '' //'USD
                } : undefined}
                loading={!currencies }
                placeholder={'Moneda'}                       
                style={{minWidth: '105px'}}
                disabled={(rowSelect?.deposit_operation_approved == 'true' || rowSelect?.extraction_operation_approved == 'true' ) ? true : false}
                onChange={(values, record)=>{
                    onChangeCurrency(values, record)                                                         
                }}
            >
                {currencies && currencies && currencies.filter((x)=>x.id !== 3).map((item) => (
                    <Option value={item.name} key={item.id} sign={item.sign}>
                        {item.name}
                    </Option>
                ))} 
            </Select>
        </Form.Item>
        <Form.Item>
            <Checkbox
                checked={checkedExtraction}
                onChange={onChangeExtraction}
                disabled={rowSelect?.extraction_operation_approved == 'true' ? true : false}
            >
                Extración en caja
            </Checkbox>
        </Form.Item>          
        {
            checkedExtraction && 
            <Form.Item
                label='Caja extracción'
                name='extraction_cash'
                rules={mandatory}
            >
                <Select                           
                    labelInValue  
                    placeholder={!listCahs ? 'Cargando cajas...' : 'Seleccione una caja'}      
                    style={{minWidth: '200px'}} 
                    disabled={ rowSelect?.extraction_operation_approved == 'true' ? true : false}                     
                >
                    {listCahs && listCahs.map((item) => (
                        <Option value={item.name} key={item.id} >
                            {item.name}
                        </Option>
                    ))} 
                </Select>
            </Form.Item>
        }
        {spanError && <span style={{color: 'red'}}>{spanError}</span>} 
    </div>) 
}

