import { axiosBackEndInstance } from '../Axios';
import { END_POINT_BRANCH, END_POINT_CURRENCY_QUOTE } from '../Axios/ENDPOINTS';
import { notification } from 'antd';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { useGetBranch } from './useGetBranch';
import { ExchangeRateProps, useGetExchangeRate } from './useGetExchangeRate';


export const useExchangeRate= () => {

    const {errorHandling} = useErrorHandling()
    const { mutate } = useGetExchangeRate()
     
    const createExchangeRate = async (
      callback: Function,
      values:{
        main_currency_id: number,
        secondary_currency_id: number,
        buy_price: number,
        sell_price: number
      }
      
      ): Promise<ExchangeRateProps | undefined> => {      
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_CURRENCY_QUOTE, values
      )
      const status =  errorHandling(data).request.status
      if(status === 200){
        callback()
        mutate()        
        notification.success({
          message: 'Éxito', 
          description:'Tipo de cambio creado con éxito', 
          placement: 'topRight'
        })
        return data.data
      }      
    } catch (newError) {
      console.log(newError);
      callback()
      
    }
  };

  const editExchangeRate = async (
    callback: Function,
    id: number,
    values:{
      main_currency_id?: number,
      secondary_currency_id?: number,
      buy_price?: number,
      sell_price?: number
    }
                            
  ): Promise<void> => {
              
    try {
      const data  = await axiosBackEndInstance.put(
        `${END_POINT_CURRENCY_QUOTE}/${id}`, values
      );
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Tipo de cambio editado con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {    
      callback()
      let status = errorHandling(newError).request.status
      console.log('Status ' + status);
      console.log(newError);
    }
  };

  const deleteExchangeRate = async (
    callback: Function,
    id: number                            
  ): Promise<void> => {
      
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_CURRENCY_QUOTE}/${id}`, 
      );
      callback()   
      if(data.status === 200){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Tipo de cambio eliminado con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      console.log(newError);
      callback()
      let status = errorHandling(newError).request.status
    }
  };

  return {
    createExchangeRate,
    editExchangeRate,
    deleteExchangeRate
  };
};
