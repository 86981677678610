import './style.scss'

export const SpinerHorizontal =()=>{

    return(
        <div className='spinerHorizontal_container'>
            <p>Cargando</p>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
       
    )
}
