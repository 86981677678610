import React, { useContext, useEffect, useState } from 'react';
import './styles.scss'
import {  Button, Modal, Row, Table } from 'antd';
import {
    DeleteOutlined,
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { UsersSectionProps } from './types';
import {  UsersProps, useGetUsers } from '../../../Services/useGetUsers';
import { useUsers } from '../../../Services/useUsers';
import UserForm  from '../../Forms/UserForm';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';


export interface DataType {
  index: number,
  id: number,
  username: string,
  first_name: string,
  last_name: string, 
  role: string
}

const UserSection: React.FC<UsersSectionProps> = () => {

    const {data: dataApi, mutate, error} = useGetUsers()    
    const {deleteUser} = useUsers()
    const {adjustedTableHeigthScroll} = useContext(StylesContext)    

    const [form_user] = useForm()
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [openForm, setOpenForm] = useState<boolean>(false)
    const [rowSelect, setRowSelect] =useState<DataType | undefined>()    
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)    

    useEffect(()=>{
      setLoadingTableData(true)
      if(dataApi){
          rowData() 
          setLoadingTableData(false)
      }
  },[dataApi])  

  const rowData = (data? : UsersProps[]) =>{
      let filterData: DataType[]= []
      let list: UsersProps[] = data ? data : dataApi
    
      if(list && list){
        list.map((data, index)=>{            
          filterData.push({
            index: index,
            id: data.id,
            username: data.username,
            first_name: data.first_name,
            last_name: data.last_name, 
            role: data.role
          })
        })        
          
      }       
      setDataTable(filterData)
    }

    const callback = () =>{
        setLoadingTableData(false)
        setRowSelect(undefined)
        setOpenForm(false)
        setShowConfirmDelete(false)
    }

    const handleRenderer = (text: string, record: DataType) =>{
      return (
          <div className='zone_action_action_render'>
              <DeleteOutlined 
                  onClick={()=>{
                      setRowSelect(record)
                      setShowConfirmDelete(true)
                  }}
                  style={{marginLeft:'30px', color: '#37bde0'}} /> 
          </div>  
      )      
    }
    const ModalConfirmDelete = () => {
      return(
          <Modal
              title="Eliminar"
              open={showConfirmDelete}
              onOk={()=>{
                  if(rowSelect && rowSelect.id){
                      deleteUser(callback, rowSelect.id)
                  }}}
              onCancel={()=>setShowConfirmDelete(false)}
              okText="Eliminar"
              cancelText="Cancelar"
          >
              <p>¿Estás seguro de que desea eliminar {rowSelect?.username}?</p>
          </Modal>
      )
  }


    
    const columns: ColumnsType<DataType> = [
        {
            title: 'Usuario',
            dataIndex: 'username',
            key: '1',
            className:'cursor-pointer',
            onCell: (record, rowIndex) => {
                return {
                  onClick: () => {
                    onClickRow(record)
                  }
                };
              }
        },
        {
            title: 'Nombre',
            dataIndex: 'first_name',
            key: '2',
            className:'cursor-pointer',
            onCell: (record, rowIndex) => {
                return {
                  onClick: () => {
                    onClickRow(record)
                  }
                };
              }
        },
        {
            title: 'Apellido',
            dataIndex: 'last_name',
            key: '3',
            className:'cursor-pointer',
            ellipsis: true,
            onCell: (record, rowIndex) => {
                return {
                  onClick: () => {
                    onClickRow(record)
                  }
                };
              }
        },
        {
            title: 'Tipo de usuario',
            dataIndex: 'role',
            key: '4',
            className:'cursor-pointer',
            ellipsis: true,
            onCell: (record, rowIndex) => {
                return {
                  onClick: () => {
                    onClickRow(record)
                  }
                };
              }
        },
        // {
        //   title: 'Teléfono',
        //   dataIndex: 'phone',
        //   key: '5',
        //   className:'cursor-pointer',
        //   ellipsis: true,
        //   onCell: (record, rowIndex) => {
        //       return {
        //         onClick: () => {
        //           onClickRow(record)
        //         }
        //       };
        //     }
        // },   
        {
          title: 'Acciones',
          dataIndex: 'id',
          render:  handleRenderer,
          key: '5',
          className:'cursor-pointer',
          width: 110,      
      }, 
    ];
    
    const onClickRow = (record: DataType) =>{         
      setRowSelect(record)
        setOpenForm(true) 
    } 


    return( 
        <>
          <ModalConfirmDelete/>
          { openForm && <UserForm
              openForm={openForm} 
              callback={callback}
              rowSelect={rowSelect}
              form_user={form_user}
          /> }     
          <div className='zone_section zone_section_container'>                                  
              <Row
                 className='global_title_section'  
              >
                  <h3>USUARIOS</h3>                
              </Row>
              <Row 
                className='global_button_box'
              >
                <Button
                  type='primary'
                  className='movements_section_btn'
                  onClick={()=>{setOpenForm(true)}}
                  >
                    Nuevo usuario
                </Button>                
              </Row>
              <Row 
                style={{
                    display:'flex',
                    justifyContent: 'center',
                    width: '100%',
                    height: '80%',
                    padding: '0px 30px',                        
                    border: '1px solid   #645fca6c'
                }}
              >
                {
                  loadingTableData
                  ? <SpinerHorizontal/> 
                  : <Table 
                      style={{
                          width: '100%',
                          marginTop: '1%'
                      }}
                          columns={columns} 
                          dataSource={dataTable}  
                          scroll={{ y: adjustedTableHeigthScroll }}                                   
                          pagination={{
                              pageSize: 50,
                              showSizeChanger: false,                                      
                          }}
                      />    
                  } 
              </Row>             
          </div>
        </>
        
        
)};

export default UserSection;