import React, { useEffect, useState } from "react";
import './styles.scss';
import { Form, Input, Modal, Select } from "antd";
import { CurrencyFormProps } from "./types";
import { useUsers } from "../../../Services/useUsers";
import { useCurrency } from "../../../Services/useCurrency";

export interface FilterValues{
    value: string,
    id: string,
    key: number,
}

export interface CurrencyValuesForm {
    name: string,
    sign: string
}

export const CurrencyForm: React.FC<CurrencyFormProps> =({
    openForm,
    callback,
    rowSelect,
    form_currency
})=>{

    
    const [loadingBtn, setLoadingBtn] = useState(false)
    const {createCurrency, editCurrency} = useCurrency()

    useEffect(()=>{
        form_currency.resetFields()
    },[])

    const handleCancel =()=>{
        callback()
    }

    const handleSave = async (values: CurrencyValuesForm)=>{
        if(rowSelect){
            await editCurrency(handleCancel, rowSelect.id, values)
        }else{
            await createCurrency(handleCancel, values)
        }
    }    

    return(
        <div>
            <Modal
                title= {rowSelect ? 'Editar moneda' : 'Crear moneda'}
                open={openForm}
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={()=>form_currency.submit()}
            >
                <Form
                    form={form_currency}
                    id='form_currency'
                    onFinish={handleSave}
                    layout="vertical"
                    
                >
                    <Form.Item
                        label='Nombre'
                        name='name'
                        initialValue={rowSelect?.name}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                            <Input
                                placeholder="Ej: ARG"
                            />

                    </Form.Item>                       
                    <Form.Item
                        label='Moneda'
                        name='sign'
                        initialValue={rowSelect?.sign}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Input
                            placeholder="Ej: $"
                        />
                    </Form.Item>                          
                </Form>
            </Modal>
        </div>
    )
}

export default CurrencyForm;