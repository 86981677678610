import React, { useContext, useEffect, useState } from "react";
import './styles.scss';
import { Checkbox, CheckboxProps, Col, Form, Input, InputNumber, Modal, notification, Row, Select } from "antd";
import { TransfersSuppliersProps } from "./types";
import {  useGetAllAccount } from "../../../Services/useGetAllAccount";
import { useOperations } from "../../../Services/useOperations";
import { ResponseAccounts, useAccounts } from "../../../Services/useAccounts";
import { VariablesContext } from "../../../Contexts/UserContext/VariablesContext";
import { UserContext } from "../../../Contexts/UserContext/UsersContext";
import { AllClientsProps, useGetAllClients } from "../../../Services/useGetAllClients";
import { CurrencyProps, useGetCurrency } from "../../../Services/useGetCurrency";
import { useGetOperationType } from "../../../Services/useGetOperationType";

export interface FilterValues{
    value: string,
    label: string,
    key: number,
}

export interface ValuesForm {
    operation_type: FilterValues,
    origin_account: FilterValues,
    destination_account: FilterValues,
    ammount: number,
    approved: boolean,
    description: string,
    supplier: FilterValues,
    supplier_currency: FilterValues,    
    origin_customer: FilterValues,
    origin_currency: FilterValues, 
    commission: number,
    supplier_commission: number   
}

export const TransfersSuppliers: React.FC<TransfersSuppliersProps> =({
    form,
    rowSelect,
    setRowSelect
})=>{

    const {data: account} = useGetAllAccount()
    const {createOperation, editOperation} = useOperations()
    const {getAccountsCurrencyAndCustomer} = useAccounts()  
    const { setShowForms, showForms } = useContext(VariablesContext)
    const { data: currency} = useGetCurrency()
    const { data: clients } = useGetAllClients()
    const { data: suppliers } = useGetAllClients({ is_supplier: true });
    const {userData} = useContext(UserContext)
    const {collapsedFilsters, setCollapsedFilters} = useContext(VariablesContext)    
    const { data: operation_type } = useGetOperationType()
    const { Option } = Select;
    const mandatory = [
        {
            required: true,
            message:'Este campo es obligatorio'
        }
    ]
    
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [approved, setaproved] = useState(false)
    const [selectedCurrency, setSelectedCurrency] = useState<{id: number, name: string, sign: string} | undefined>()
    const [selectedCustomer, setSelectedCustomer] = useState<{id: number, name: string} | undefined>()
    const [selectedSupplier, setSelectedSupplier] = useState<{id: number, name: string} | undefined>()
    const [spanError, setSpanError] = useState<string>() 
    const [secondSelectorList, setSecondSelectorList] = useState<AllClientsProps[]>([])
    const [secondCurrencySelectorList, setCurrencySecondSelectorList] = useState<CurrencyProps[]>([])
    const [ammmountSign, setAmmountSign] = useState<string>('')
    const [ammount, setAmmount] = useState<number>()
    const [selectedTypeOperatios, setSelectedTypeOperatios] = useState<FilterValues>()
    const filtered = operation_type && operation_type.filter(item => [10, 11].includes(item.id));

    useEffect(()=>{
        form.resetFields()
    },[])

    useEffect(()=>{
        if(ammount){
            let type_id: FilterValues = form.getFieldValue('operation_type');
            if(type_id.key == 11){  //es extracción a cuenta cliente
                if(selectedCustomer){
                   checkBalance(+selectedCustomer.id, ammount) 
                   
                }                
            }else if( type_id.key == 10){
                if(selectedSupplier){ // es extracción de cuenta proveedor
                    checkBalance(+selectedSupplier.id, ammount)
                }
            }            
        }             
    },[selectedCurrency, selectedCustomer, ammount, selectedTypeOperatios ])

   useEffect(() => {
       if(rowSelect && account){
            let origin_account = account.filter((x)=>x.id === Number(rowSelect.origin_account_id))[0]
            let destination_account = account.filter((x)=>x.id === Number(rowSelect.destination_account_id))[0]  
            
            const isType10 = rowSelect?.operation_type_id === '10';
            const customerAccount = isType10 ? destination_account : origin_account;
            const supplierAccount = isType10 ? origin_account : destination_account;                                                    

            if(origin_account && destination_account ){
                let type_id = rowSelect?.operation_type_id

                form.setFieldsValue({
                    supplier: {
                        label: supplierAccount?.customer?.first_name + ' ' + supplierAccount?.customer.last_name,
                        value: supplierAccount.customer?.first_name + ' ' + supplierAccount?.customer.last_name,
                        key: supplierAccount.customer.id,
                    },
                    origin_customer: {
                        label: customerAccount?.customer.first_name + ' ' + customerAccount?.customer.last_name,
                        value: customerAccount?.customer.first_name + ' ' + customerAccount?.customer.last_name,
                        key: customerAccount?.customer.id,
                    },
                    origin_currency: {
                        label: origin_account.currency.name,
                        value: origin_account.currency.name,
                        key: origin_account.currency.id
                    },
                    operation_type:{
                        label: operation_type.filter(x=> x.id == +type_id)[0].name,
                        value: operation_type.filter(x=> x.id == +type_id)[0].name,
                        key: +type_id
                    },
                    commission: rowSelect?.commission,
                    supplier_commission: rowSelect?.supplier_commission,
                    ammount: rowSelect.debit_number? +rowSelect.debit_number : +rowSelect.credit_number,
                    description: rowSelect.description
                });  
            }  
            setSelectedCurrency({
                name: origin_account.currency.name,
                id: origin_account.currency.id,
                sign: origin_account.currency.sign
            })   


            setSelectedCustomer({
                id: customerAccount.customer.id,
                name: `${customerAccount.customer.first_name} ${customerAccount.customer.last_name}`,
            });

            setSelectedSupplier({
                id: supplierAccount.customer.id,
                name: `${supplierAccount.customer.first_name} ${supplierAccount.customer.last_name}`,
            });
             
            setAmmount(rowSelect.debit_number? +rowSelect.debit_number : +rowSelect.credit_number,)   
        }else{
            form.resetFields(undefined) 
            setSelectedCurrency(undefined)
            setSelectedCustomer(undefined)
            setSelectedSupplier(undefined)
            setAmmount(undefined)
        }        
    }, [account]);

    const checkBalance = async(user_id: number, ammount: number) =>{
        if(selectedCurrency){
            const balanceData = await getAccountsCurrencyAndCustomer(user_id, +selectedCurrency.id) //consulto el balance de cliente
            if(balanceData && balanceData){
               // setBalance( balanceData)
                if(ammount > balanceData.balance ){            
                    let value = new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(balanceData.balance)
                    setSpanError('Notificación: El monto a transferir supera el monto de cuenta: ' + selectedCurrency?.sign + ' ' + value  )  
                }else{
                    setSpanError('')
                }
            }                    
        }         
    }

    const handleCancel =()=>{
        setShowForms(Object.fromEntries(Object.keys(showForms).map(key => [key, false])))// pone todos en false
        if(setRowSelect){
            setRowSelect(undefined)
        }        
    }

    const changeTransferer = (e: FilterValues ) =>{
        setSelectedTypeOperatios(e)
          
    }

    const onChangeFirstClient = (e: FilterValues) =>{
        if(suppliers){
            let list = suppliers.filter((x)=> 
                +e.key !== x.id)
            setSecondSelectorList(list)
            form.resetFields(['destination_customer'])
            setSelectedCustomer({
                id: e.key,
                name: e.label
            })
        }        
    }

    const onChangeFirstCurrency = (e: FilterValues, values: { sign: string } | { sign: string }[]) =>{
        const signValue = Array.isArray(values) ? values[0]?.sign : values.sign;
        if(currency){
            let list = currency.filter((x)=> 
                +e.key == x.id)
            setCurrencySecondSelectorList(list)
            setAmmountSign(signValue)
        }        
        setSelectedCurrency({
            id: e.key, 
            name: e.label,
            sign: signValue})
    }

    const onChangeAmmount = (e: any) =>{
        setAmmount(e)       
    }  

    const onChangeapproved: CheckboxProps['onChange'] = async (e) => {
        setaproved(e.target.checked); 
    };

    const handleSave = async (e: ValuesForm)=>{
        if(e.supplier.key == e.origin_customer.key){
            notification.error({
                message: 'Error', 
                description:
                  'Cliente y proveedor deben ser distintos', 
                placement: 'top'
            })
            return   

        }
        setLoadingBtn(true)
        let operationData
        if(userData){
            operationData = {
                description: e.description,
                type_id: e.operation_type.key,
                currency_id: e.origin_currency.key,
                user_id: userData.id,
                debit: e.operation_type.key == 11 ? e.ammount : undefined,
                credit: e.operation_type.key == 10 ? e.ammount : undefined,
                approved: approved, 
                customer_id: e.origin_customer.key, 
                supplier_id: e.supplier.key, 
                commission: e.commission, 
                supplier_commission: e.supplier_commission
            }              
        }                

        if(operationData){
            let newData
            if(rowSelect){
                newData = await editOperation(handleCancel, rowSelect.id, operationData)
            }else{
                newData = await createOperation(handleCancel, operationData)
            }                  
            //checkAccountBalance()
            setCollapsedFilters(!collapsedFilsters)
            setLoadingBtn(false)  
        }  
    }         

    return(
        <div>
            <Modal
                title= 'Transferencia bancaria a terceros'
                open={showForms.transferer_to_suppliers}
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={()=>form.submit()}
            >
                <Form
                    form={form}
                    id='form_deposit_extraction'
                    onFinish={handleSave}
                    layout="vertical"                    
                >   
                  <Form.Item
                        label='Tipo de operación'
                        name='operation_type'                
                        rules={mandatory}
                    >
                        <Select
                            labelInValue
                            placeholder="Seleccione el tipo de movimiento"   
                            onChange={changeTransferer}                         
                        >
                            {filtered && filtered.map((item) => (
                                <Option value={item.name} key={item.id} >
                                    {item.name}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>  
                    <Row>
                        <Col span={14} >
                            <Form.Item
                                label='Cliente'
                                name='origin_customer'
                                rules={mandatory}
                            >   
                                <Select                           
                                    labelInValue  
                                    loading={!clients }
                                    placeholder={!clients ? 'Cargando campos...' : 'Cliente'}      
                                    style={{minWidth: '200px'}} 
                                    showSearch   
                                    onChange={onChangeFirstClient}                                 
                                >
                                    {clients && clients.map((item) => (
                                        <Option value={item.first_name + ' ' + item.last_name} key={item.id} >
                                            {item.first_name + ' ' + item.last_name}
                                        </Option>
                                    ))} 
                                </Select>

                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item
                                label='Moneda cuenta de origen'
                                name='origin_currency'                         
                                rules={mandatory}                       
                            
                            >
                                <Select
                                    labelInValue     
                                    placeholder='Moneda de cuenta' 
                                    onChange={onChangeFirstCurrency}                                                                  
                                >
                                    {currency && currency.map((item) => (
                                        <Option 
                                            value={item.name} 
                                            key={item.id}
                                            sign={item.sign} >
                                        {item.name}
                                        </Option>
                                    ))} 
                                </Select>
                            </Form.Item>
                        </Col>                                                                               
                    </Row> 
                    <Row>
                        <Col span={14}>
                            <Form.Item
                                label='Proveedor'
                                name='supplier'
                                rules={mandatory}
                            >   
                                <Select                           
                                    labelInValue  
                                    loading={!suppliers }
                                    placeholder={!suppliers ? 'Cargando campos...' : 'Proveedor'}      
                                    style={{minWidth: '200px'}} 
                                    showSearch   
                                    onChange={(e: FilterValues)=>setSelectedSupplier({id: e.key, name: e.label})}                              
                                >
                                    {secondSelectorList && secondSelectorList.map((item) => (
                                        <Option value={item.first_name + ' ' + item.last_name} key={item.id} >
                                            {item.first_name + ' ' + item.last_name}
                                        </Option>
                                    ))} 
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item
                                label='Moneda cuenta de destino'
                                name='origin_currency'                         
                                rules={mandatory}                       
                            
                            >
                                <Select
                                    labelInValue     
                                    placeholder='Moneda de cuenta'  
                                    disabled={true}                                                                                                    
                                >
                                    {secondCurrencySelectorList && secondCurrencySelectorList.map((item) => (
                                        <Option 
                                            value={item.name} 
                                            key={item.id} >
                                        {item.name}
                                        </Option>
                                    ))} 
                                </Select>
                            </Form.Item>                        
                        </Col>                                               
                    </Row> 
                    <Form.Item
                        label='Comisión'
                        name='commission'
                        initialValue={3}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <InputNumber
                            style={{width: '100%'}}
                            placeholder="Comisión"                            
                            addonBefore='%'
                        />
                    </Form.Item>   
                    <Form.Item
                        label='Comisión proveedor'
                        name='supplier_commission'
                        initialValue={3}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <InputNumber
                            style={{width: '100%'}}
                            placeholder="Comisión"
                            addonBefore='%'
                        />
                    </Form.Item>                                                   
                    <Form.Item
                        label='Monto'
                        name='ammount'
                        rules={mandatory}
                    >
                        <InputNumber
                            style={{width: '100%'}}
                            placeholder="Monto"
                            onChange={onChangeAmmount}
                            addonBefore={ammmountSign}
                        />
                    </Form.Item>  
                    <Form.Item
                        name='approved'
                        valuePropName="checked"
                    >
                        <Checkbox
                            checked={approved}
                            onChange={onChangeapproved}
                        >
                            Aprobado
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        label='Descripción'
                        name='description'
                    >
                        <Input
                            placeholder="Comentario"
                        />
                    </Form.Item>  
                    <span style={{color: 'red'}}>{spanError && spanError} </span>           
                </Form>
            </Modal>
        </div>
    )
}

export default TransfersSuppliers;