import React, {
    createContext,
    useEffect,
    useState,
  } from 'react';
import { CheckoutBalance } from '../../Services/useCashDesk';
import { DataType } from './MovementAcountContext';

  class VoidContext implements OperationsContext {
    get dataTable(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setDataTable(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get mutate(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setMutate(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get showingCash(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setShowingCash(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get balance(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setBalance(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get sectionsFilters(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setSectionsFilters(): never {
      throw new Error('Cannot consume context outside of provider');
    }
  }

  interface OperationsContext {
    dataTable: DataType[], 
    setDataTable: Function,
    mutate: boolean 
    setMutate: Function,
    showingCash:{
      name: string,
      id: number
    } | undefined, 
    balance: CheckoutBalance | undefined,
    setBalance: Function
    setShowingCash: Function,
    sectionsFilters: OperationsSectionsFilters | undefined,
    setSectionsFilters: Function
  }
  export interface ValuesForm{
    value: string,
    label: string,
    key: number
}

  interface OperationsSectionsFilters2 {
    date: {
      to: string | undefined,
      from: string | undefined
    },
    operation_type:{
      id: number | undefined,
      name: string | undefined
    },
    customer:{
      id: number | undefined,
      name: string | undefined
    },
    currency: {
      id: number | undefined,
      name: string | undefined,
      sign: string | undefined
    }
  }

  interface OperationsSectionsFilters {
    date: {
      to: string | undefined,
      from: string | undefined
    },
    operation_type: ValuesForm | undefined,
    customer: ValuesForm | undefined,
    currency: ValuesForm | undefined
  }

  interface propsWithChildren {
    children: React.ReactNode
  }
  
  export const OperationsContext = createContext<OperationsContext>(new VoidContext());
  export const OperationsContextProvider= ({ children }: propsWithChildren): JSX.Element => {

    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [mutate, setMutate] = useState<boolean>(false)
    const [balance, setBalance] = useState<CheckoutBalance | undefined>()
    const [sectionsFilters, setSectionsFilters] = useState<OperationsSectionsFilters>()

    const [ showingCash, setShowingCash ] = useState<{
      name: string,
      id: number,
    } | undefined>()

    return (
      <OperationsContext.Provider value={{
        dataTable, 
        setDataTable,
        mutate, 
        setMutate,
        showingCash, 
        setShowingCash,
        balance, 
        setBalance,
        sectionsFilters, 
        setSectionsFilters
      }}>{children}</OperationsContext.Provider>
    );
  };



  