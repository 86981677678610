import React, { useContext } from "react";
import './styles.scss'
import { BodyContentProps } from "./types";
import { Route, Routes } from "react-router";
import Home from "../Model";
import UserSection from "../Sections/UsersSection";
import CurrencySection from "../Sections/CurrencySection";
import BranchSection from "../Sections/BranchSection";
import CashDeskSection from "../Sections/CashDeskSection";
import MovementsCash from "../Sections/MovementsCash";
import DepositExtraction from "../Sections/DepositExtracción";
import PurchaseSaleCurrency from "../Sections/PurchaseSaleCurrency";
import ExtractionDepositBanc from "../Sections/ExtractionDepositBanc";
import ExchangeRateSection from "../Sections/ExchangeRate";
import BalanceSections from "../Sections/BalanceSections";
import OperationSections from "../Sections/OperationSections";
import { UserContext } from "../../Contexts/UserContext/UsersContext";
import MovementsAccount from "../Sections/MovementsAcount";
import CustomerSections from "../Sections/CustomerSections";
import ChangePassword from "../Sections/ChangePassword";
import ProfileSections from "../Sections/ProfileSection";

export const BodyContent: React.FC<BodyContentProps> = ()=>{
    const {collapsed} = useContext(UserContext)

    return(
        <div className="bodyContent_container" 
            style={{  
                width: collapsed ? 'calc(100% - 112px)' :'calc(100% - 296px)',                
            }}
        >
            <Routes>
                <Route path='home' element={<Home/>}/>                 
                <Route path='user' element={<UserSection/>}/>
                <Route path='currency' element={<CurrencySection/>}/> 
                <Route path='branch' element={<BranchSection/>}/>  
                <Route path='checkout' element={<CashDeskSection/>}/>     
                <Route path='account_movements' element={<MovementsAccount/>}/>  
                <Route path='cash_movements' element={<MovementsCash/>}/>   
                <Route path='extraction_deposit' element={<DepositExtraction/>}/>     
                <Route path='purchase_sale_currency' element={<PurchaseSaleCurrency/>}/>       
                <Route path='extraction_deposit_banc' element={<ExtractionDepositBanc/>}/>  
                <Route path='exchange_rate' element={<ExchangeRateSection/>}/>
                <Route path='balance_sheets' element={<BalanceSections/>}/> 
                <Route path='operations' element={<OperationSections/>}/> 
                <Route path='customers' element={<CustomerSections/>}/> 
                <Route path='change_password' element={<ChangePassword/>}/> 
                <Route path='profile' element={<ProfileSections/>}/> 
            </Routes>
        </div>
        
    )
}

export default BodyContent

