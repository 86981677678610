import React, { useContext, useEffect, useState } from "react";
import './styles.scss';
import { Checkbox, CheckboxProps, Col, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import { TransfersBetweenBoxesProps } from "./types";
import {  useGetAllAccount } from "../../../Services/useGetAllAccount";
import { useOperations } from "../../../Services/useOperations";
import { ResponseAccounts, useAccounts } from "../../../Services/useAccounts";
import { VariablesContext } from "../../../Contexts/UserContext/VariablesContext";
import { UserContext } from "../../../Contexts/UserContext/UsersContext";
import { AllClientsProps, useGetAllClients } from "../../../Services/useGetAllClients";
import { CurrencyProps, useGetCurrency } from "../../../Services/useGetCurrency";

export interface FilterValues{
    value: string,
    label: string,
    key: number,
}

export interface ValuesForm {
    origin_account: FilterValues,
    destination_account: FilterValues,
    amount: number,
    approved: boolean,
    description: string,
    destination_customer: FilterValues,
    destination_currency: FilterValues,    
    origin_customer: FilterValues,
    origin_currency: FilterValues,    
}

export const TransfersBetweenAccounts: React.FC<TransfersBetweenBoxesProps> =({
    form,
    rowSelect,
    setRowSelect
})=>{

    const {data: account} = useGetAllAccount()
    const {createOperation, editOperation} = useOperations()
    const {getAccountsCurrencyAndCustomer} = useAccounts()  
    const { setShowForms, showForms } = useContext(VariablesContext)
    const { data: currency} = useGetCurrency()
    const { data: clients } = useGetAllClients()
    const {userData} = useContext(UserContext)
    const {collapsedFilsters, setCollapsedFilters} = useContext(VariablesContext)
    const { Option } = Select;
    const mandatory = [
        {
            required: true,
            message:'Este campo es obligatorio'
        }
    ]

    
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [approved, setaproved] = useState(false)
    const [selectedCurrency, setSelectedCurrency] = useState<{id: number, name: string, sign: string} | undefined>()
    const [selectedCustomer, setSelectedCustomer] = useState<{id: number, name: string} | undefined>()
    const [balance, setBalance] = useState<ResponseAccounts>()
    const [spanError, setSpanError] = useState<string>() 
    const [secondSelectorList, setSecondSelectorList] = useState<AllClientsProps[]>([])
    const [secondCurrencySelectorList, setCurrencySecondSelectorList] = useState<CurrencyProps[]>([])
    const [ammmountSign, setAmmountSign] = useState<string>('$')

    useEffect(()=>{
        form.resetFields()
    },[])

   useEffect(() => {
       if(rowSelect && account){
            let origin_customer = clients.filter((x)=>x.id === Number(rowSelect.origin_account_customer_id))[0]
            let destination_customer = clients.filter((x)=>x.id === Number(rowSelect.destination_account_customer_id))[0]                                                      

            if(origin_customer && destination_customer ){
                form.setFieldsValue({                

                    destination_customer: {
                        label:  destination_customer.first_name + ' ' + destination_customer.last_name,
                        value: destination_customer.first_name + ' ' + destination_customer.last_name,
                        key: destination_customer.id,
                    },
                    origin_customer: {
                        label: origin_customer.first_name + ' ' + origin_customer.last_name,
                        value: origin_customer.first_name + ' ' + origin_customer.last_name,
                        key: origin_customer.id,
                    },
                    origin_currency: {
                        label: rowSelect.origin_account_currency_name,
                        value: rowSelect.origin_account_currency_name,
                        key: rowSelect.origin_account_currency_id
                    },
                    amount: rowSelect.debit_number? +rowSelect.debit_number : +rowSelect.credit_number,
                    description: rowSelect.description
                });  
            }            
        }else{
            form.resetFields(undefined) 
        }        
    }, [account]);

    useEffect(()=>{
        if(selectedCurrency && selectedCustomer){
           changeOriginAccount() 
        }
        
    },[selectedCurrency, selectedCustomer])

    const handleCancel =()=>{
        setShowForms(Object.fromEntries(Object.keys(showForms).map(key => [key, false])))// pone todos en false
        if(setRowSelect){
            setRowSelect(undefined)
        }        
    }

    const handleSave = async (e: ValuesForm)=>{
        let operationData
        if(userData){
            operationData={
                type_id: 6, //correspondiente a Transferencia entre cuentas clientes, por eso va harcord
                destination_customer_id: e.destination_customer.key,
                origin_customer_id: e.origin_customer.key,   
                currency_id: e.origin_currency.key,
                //destination_currency_id: e.origin_currency.key,  
                //origin_currency: e.origin_currency.key, 
                debit: e.amount,
                user_id: userData.id,
                approved: approved,
                description: e.description 
            } 
        }                

        if(operationData){
            let newData
            if(rowSelect){
                newData = await editOperation(handleCancel, rowSelect.id, operationData)
            }else{
                newData = await createOperation(handleCancel, operationData)
            }                  
            //checkAccountBalance()
            setCollapsedFilters(!collapsedFilsters)  
        }  
    }    

    const changeOriginAccount = async(
    ) =>{

        form.setFieldsValue({ amount: undefined, destination_account: undefined });
        setSpanError(undefined)
        if(selectedCurrency && selectedCustomer){
            const balanceData = await getAccountsCurrencyAndCustomer(+selectedCustomer.id, +selectedCurrency.id) //consulto el balance de cliente
          //  setCurrency({id: values.currency.id, sign: values.currency.sign})
            if(balanceData && balanceData){
                setBalance( balanceData)
            }    
            // setLoadingDestinationAccount(true)
            // const accountsData = await getAccountsCurrency(+selectedCurrency.id, setLoadingDestinationAccount) //endpoint para cargar segundo selector según moneda de origen
            // if(accountsData){
            //     let list = accountsData.filter((x)=> x.customer.id !== +selectedCustomer.id  )
            //     setListDestinationAccount(list)
            // }                    
        }          
    }

    const onChangeAmount = (e: any) =>{
        if(balance){ 
            if(e > balance.balance ){            
                let value = new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(balance.balance)
                setSpanError('El monto a transferir supera el monto de cuenta: ' + selectedCurrency?.sign + ' ' + value  )  
            }
        }
    }  

    const onChangeapproved: CheckboxProps['onChange'] = async (e) => {
        setaproved(e.target.checked); 
    };

    const onChangeFirstClient = (e: FilterValues) =>{
        if(clients){
            let list = clients.filter((x)=> 
                +e.key !== x.id)
            setSecondSelectorList(list)
            form.resetFields(['destination_customer'])
            setSelectedCustomer({
                id: e.key,
                name: e.label
            })
        }        
    }

    const onChangeFirstCurrency = (e: FilterValues, values: { sign: string } | { sign: string }[]) =>{
        const signValue = Array.isArray(values) ? values[0]?.sign : values.sign;
        if(currency){
            let list = currency.filter((x)=> 
                +e.key == x.id)
            setCurrencySecondSelectorList(list)
            setAmmountSign(signValue)
        }        
        setSelectedCurrency({
            id: e.key, 
            name: e.label,
            sign: signValue})
    }


    return(
        <div>
            <Modal
                title= 'Transferencia entre cuentas cliente'
                open={showForms.transferer }
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={()=>form.submit()}
            >
                <Form
                    form={form}
                    id='form_deposit_extraction'
                    onFinish={handleSave}
                    layout="vertical"                    
                >   
                    <Row>
                        <Col span={14} >
                            <Form.Item
                                label='Cuenta de origen'
                                name='origin_customer'
                                rules={mandatory}
                            >   
                                <Select                           
                                    labelInValue  
                                    loading={!clients }
                                    placeholder={!clients ? 'Cargando campos...' : 'Cliente'}      
                                    style={{minWidth: '200px'}} 
                                    showSearch   
                                    onChange={onChangeFirstClient}                                 
                                >
                                    {clients && clients.map((item) => (
                                        <Option value={item.first_name + ' ' + item.last_name} key={item.id} >
                                            {item.first_name + ' ' + item.last_name}
                                        </Option>
                                    ))} 
                                </Select>

                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item
                                label='Moneda cuenta de origen'
                                name='origin_currency'                         
                                rules={mandatory}                       
                            
                            >
                                <Select
                                    labelInValue     
                                    placeholder='Moneda de cuenta' 
                                    onChange={onChangeFirstCurrency}                                                                  
                                >
                                    {currency && currency.map((item) => (
                                        <Option 
                                            value={item.name} 
                                            key={item.id}
                                            sign={item.sign} >
                                        {item.name}
                                        </Option>
                                    ))} 
                                </Select>
                            </Form.Item>
                        </Col>                                                                               
                    </Row> 
                    <Row>
                        <Col span={14}>
                            <Form.Item
                                label='Cuenta de destino'
                                name='destination_customer'
                                rules={mandatory}
                            >   
                                <Select                           
                                    labelInValue  
                                    loading={!clients }
                                    placeholder={!clients ? 'Cargando campos...' : 'Cliente'}      
                                    style={{minWidth: '200px'}} 
                                    showSearch                                 
                                >
                                    {secondSelectorList && secondSelectorList.map((item) => (
                                        <Option value={item.first_name + ' ' + item.last_name} key={item.id} >
                                            {item.first_name + ' ' + item.last_name}
                                        </Option>
                                    ))} 
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item
                                label='Moneda cuenta de destino'
                                name='origin_currency'                         
                                rules={mandatory}                       
                            
                            >
                                <Select
                                    labelInValue     
                                    placeholder='Moneda de cuenta'  
                                    disabled={true}                                                                                                    
                                >
                                    {secondCurrencySelectorList && secondCurrencySelectorList.map((item) => (
                                        <Option 
                                            value={item.name} 
                                            key={item.id} >
                                        {item.name}
                                        </Option>
                                    ))} 
                                </Select>
                            </Form.Item>                        
                        </Col>                                               
                    </Row>                                     
                    {/* <Form.Item
                        label='Cuenta de origen'
                        name='origin_account'
                        rules={mandatory}
                    >
                        <Select                           
                            labelInValue  
                            loading={!account }
                            placeholder={!account ? 'Cargando cuentas...' : 'Seleccione una cuenta de origen'}      
                            style={{minWidth: '200px'}}    
                            showSearch   
                            onChange={changeOriginAccount}                   
                        >
                            {account && account.map((item) => (
                                <Option 
                                    value={item.customer.first_name + ' ' + item.customer.last_name + ' ' + item.currency.name} 
                                    key={item.id} 
                                    currency = {item.currency} 
                                    customer ={item.customer.id}
                                    
                                >
                                    {item.customer.first_name + ' ' + item.customer.last_name}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>       */}
                    {/* <Form.Item
                        label='Cuenta de destino'
                        name='destination_account'
                        rules={mandatory}
                    >
                        <Select                           
                            labelInValue  
                            loading={loadingDestiantioinAccount}
                            placeholder={loadingDestiantioinAccount ? 'Cargando cuentas...' : 'Seleccione una cuenta destino'}      
                            style={{minWidth: '200px'}}    
                            showSearch                      
                        >
                            {listDestinationAccount && listDestinationAccount.map((item) => (
                                <Option value={item.customer.first_name + ' ' + item.customer.last_name + ' ' + item.currency.name} key={item.id} >
                                    {item.customer.first_name + ' ' + item.customer.last_name + ' ' + item.currency.name}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>   */}
                    <Form.Item
                        label='Monto'
                        name='amount'
                        rules={mandatory}
                    >
                        <InputNumber
                            style={{width: '100%'}}
                            placeholder="Monto"
                            type="number"
                            onChange={onChangeAmount}
                            addonBefore={ammmountSign}
                        />
                    </Form.Item>  
                    <Form.Item
                        name='approved'
                        valuePropName="checked"
                    >
                        <Checkbox
                            checked={approved}
                            onChange={onChangeapproved}
                        >
                            Aprobado
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        label='Descripción'
                        name='description'
                    >
                        <Input
                            placeholder="Comentario"
                        />
                    </Form.Item>  
                    <span style={{color: 'red'}}>{spanError && spanError} </span>           
                </Form>
            </Modal>
        </div>
    )
}

export default TransfersBetweenAccounts;