import React, { useState } from 'react';
import './styles.scss'
import {  Button, Col, Row, Form, Input } from 'antd';
import {
    CheckCircleOutlined,
    KeyOutlined
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { ChangePasswordProps } from './types';
import { useUsers } from '../../../Services/useUsers';




const ChangePassword: React.FC<ChangePasswordProps> = () => {

  const {editPassword} = useUsers()

    const [form] = useForm()
 
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [spanError, setSpanError] = useState<string | undefined>()  

    const handleCancel = () =>{
        form.resetFields()
    }   

    const handleSave = async(values: {
        password_old: string,
        password1: string,
        password2: string
    })=>{
       
        if (values.password1 !== values.password2) {
            setSpanError('Las contraseñas no coinciden.');
            return; // Detener la ejecución si las contraseñas no coinciden
        } else {
            setSpanError(undefined);
        }
    
            // Validar longitud de la contraseña
        if (values.password1.length < 8) {
            setSpanError('La contraseña debe contener al menos 8 caracteres.');
            return; // Detener la ejecución si la contraseña no tiene al menos 8 caracteres
        }

        // Validar que la contraseña contenga números y letras
        if (!/(?=.*[A-Za-z])(?=.*\d)/.test(values.password1)) {
            setSpanError('La contraseña debe contener al menos un número y una letra.');
            return; // Detener la ejecución si la contraseña no cumple con el formato
        }

        let newValues ={
            old_password: values.password_old,
            new_password: values.password1
        }
        await editPassword(handleCancel, newValues)
               
    }      

    const text = [
        {
            text: 'La contraseña debe tener al menos 8 dígitos'
        },
        {
            text: 'La contraseña debe contener al menos un número y una letra'
        },
        {
            text: 'La contraseña no debe tener más de dos números consecutivos'
        },
    ]

    return( 
        <>         
          <div className='change_password_section change_password_section_container'>                                  
          <Row
                 className='global_title_section'  
              >
                 <h3> <KeyOutlined style={{fontSize: 18, marginRight: '10px', color: '#37bde0'}}/>CAMBIO DE CONTRASEÑA </h3>            
              </Row>
              <Row 
                style={{
                    display:'flex',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    padding: '30px 30px',                        
                    border: '1px solid   #645fca6c',
                    marginTop: '20px',
                    marginBottom: '20px',

                }}
              >
                <Col
                    style={{
                        padding: '0px 20px'
                    }}
                >
                    <Form
                        name='form'
                        id='form'
                        form={form}
                        onFinish={handleSave}
                        style={{width: '400px'}}
                        layout='vertical'
                    >
                        <Form.Item
                            label='Actual contraseña'
                            name='password_old'
                            rules={[
                                {
                                    required: true,
                                    message:'Este campo es obligatorio'
                                }
                            ]}
                        >
                        <Input.Password
                            width='100%'
                            placeholder="Ingrese su Actual contraseña"
                        />
                        </Form.Item> 
                        <Form.Item
                            label='Nueva contraseña'
                            name='password1'
                            rules={[
                                {
                                    required: true,
                                    message:'Este campo es obligatorio'
                                }
                            ]}
                        >
                            <Input.Password
                            width='100%'
                                placeholder="Ingrese una una contraseña"
                            />
                        </Form.Item>
                        <Form.Item
                            label='Repetir contraseña'
                            name='password2'
                            rules={[
                                {
                                    required: true,
                                    message:'Este campo es obligatorio'
                                }
                            ]}
                        >
                            <Input.Password
                                width='100%'
                                placeholder="Repetir nueva contraseña"
                            />
                        </Form.Item> 
                        { spanError &&
                            <Form.Item>
                                <span style={{color: 'red'}}>{spanError}</span>
                            </Form.Item> 
                        }                                               
                        <Form.Item>
                            <Button
                                type="primary" 
                                htmlType="submit"
                            >
                                Guardar
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>                                                                                             
                <Col
                    style={{
                        padding: '0px 20px',
                        marginTop: '17px'
                    }}
                >
                    {text.map((txt)=> 
                        <div className='change_password_section_list'>
                            <CheckCircleOutlined style={{color: 'yellowgreen', fontSize: '15px', marginRight: '10px'}} />
                            <p>{txt.text}</p>
                        </div>)
                    } 
                </Col>
              </Row>             
          </div>
        </>
        
        
)};

export default ChangePassword;