import { useEffect } from 'react';
import { KeyedMutator } from "swr";

import { useAuthSWR } from './useAuthSWR';
import { END_POINT_CASH_DESK_GET_ALL } from '../Axios/ENDPOINTS';
import { BranchProps } from './useGetBranch';

export interface CashDeskProps {
  id: number;
  description: string;
  branch: BranchProps;
  name: string;
  currency: {
    id: number,
    name: string;
    sign: string;
  };
  balance: number;
  type: {
    id: number,
    name: string
  };
  future_balance: number
}

export interface ResponseGetUsers{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: CashDeskProps[]  
}

export const useGetCashDesk = () => {
    
    const {data, error, mutate }:{
      data: CashDeskProps[], 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([END_POINT_CASH_DESK_GET_ALL])    
  
    return {
      data,
      mutate,
      error
    };
};