import React, { useEffect, useState } from "react";
import './styles.scss';
import { Form, Input, Modal, Select } from "antd";
import { UserFormProps } from "./types";
import { useUsers } from "../../../Services/useUsers";
import {
    CheckCircleOutlined,
  } from '@ant-design/icons';

export interface FilterValues{
    value: string,
    label: string,
  }
export interface UserValuesForm {
    username: string,
    first_name: string,
    last_name: string,     
    password: string,
    role: FilterValues
}

export const UserForm: React.FC<UserFormProps> =({
    openForm,
    callback,
    rowSelect,
    form_user
})=>{
    
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [spanError, setSpanError] = useState<string | undefined>()
    const {createUser, editUser} = useUsers()
    const { Option } = Select

    const roleOptions =[
        {
            value: 'Administrador',
            label: 'Administrador'
        },
        {
            value: 'Operador',
            label: 'Operador'
        }
    ]

    useEffect(()=>{
        form_user.resetFields()
    },[])

    const handleCancel =()=>{
        callback()
        setLoadingBtn(false)
    }



    const handleSave = async(values: UserValuesForm)=>{
        setLoadingBtn(true)      
        let newUserData = {
            username: values.username,
            first_name: values.first_name,
            last_name: values.last_name,     
            password: values.password,
            role: values.role.value
        }

        // Validar longitud de la contraseña
        if (values?.password?.length < 8) {
            setLoadingBtn(false)
            setSpanError('La contraseña debe contener al menos 8 caracteres.');
            return; // Detener la ejecución si la contraseña no tiene al menos 8 caracteres
        }

        if(rowSelect){
            await editUser(handleCancel, rowSelect.id, newUserData)
        }else{
            await createUser(handleCancel, newUserData)
        }                      
    } 
        
    const text = [
        {
            text: 'La contraseña debe tener al menos 8 dígitos'
        },
    ]
      
    return(
        <div>
            <Modal
                title= {rowSelect ? 'Editar usuario' : 'Crear Usuario'}
                open={openForm}
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={()=>form_user.submit()}
            >
                <Form
                    form={form_user}
                    id='form_user'
                    onFinish={handleSave}
                    layout="vertical"                    
                >                  
                     
                    <Form.Item
                        label='Usuario'
                        name='username'
                        initialValue={rowSelect?.username}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            },
                        ]}
                    >
                        <Input
                            placeholder="Nombre de usuario"
                        />
                    </Form.Item>    
                    {!rowSelect &&
                        <Form.Item
                            label='Contraseña'
                            name='password'
                            rules={[
                                {
                                    required: true,
                                    message:'Este campo es obligatorio'
                                }
                            ]}
                        >
                            <Input
                                placeholder="contraseña"
                            />
                           
                        </Form.Item>                     
                    }
                    {!rowSelect &&
                        <div className="user_form_section_container">
                            {text.map((txt)=> 
                                <div className='user_form_section_list'>
                                    <CheckCircleOutlined style={{color: 'yellowgreen', fontSize: '15px', marginRight: '10px'}} />
                                    <p>{txt.text}</p>
                                </div>)
                            } 
                        </div>
                    }
                    <Form.Item
                        label='Nombre'
                        name='first_name'
                        initialValue={rowSelect?.first_name}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Input
                            placeholder="Nombre"
                        />
                    </Form.Item>       
                    <Form.Item
                        label='Apellido'
                        name='last_name'
                        initialValue={rowSelect?.last_name}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Input
                            placeholder="Apellido"
                        />
                    </Form.Item>  
                    <Form.Item
                        label='Tipo de usuario'
                        name='role'
                        initialValue={{
                            value: rowSelect?.role,
                            label: rowSelect?.role,
                        }}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Select
                            labelInValue
                        >
                            {roleOptions && roleOptions.map((item) => (
                                <Option value={item.value} >
                                {item.label}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item> 
                </Form>
                { spanError &&
                    <Form.Item>
                        <span style={{color: 'red'}}>{spanError}</span>
                    </Form.Item> 
                } 
            </Modal>
        </div>
    )
}

export default UserForm;